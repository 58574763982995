.sw-poll-before
  margin-bottom: 10px
  position: relative
  padding: 10px 20px
  background-color: #f6f6f6
  border: 1px solid #d5d5d5
  border-radius: 5px

.sw-poll-before__help
  width: 15px
  height: 15px
  padding: 3px
  position: absolute
  top: 3px
  right: 3px

  .icn-help-cont
    top: 15px
    right: 15px

.sw-poll-before__title
  font-size: (18/16)rem
  color: #a2a2a2
  margin-bottom: 15px
  text-transform: uppercase

.sw-poll-before-btn
  height: 35px
  font-size: (15/16)rem
  width: 100%
  border-radius: 5px
  margin: 20px 0 5px

.sw-poll-before__quest
  color: #47aca5
  font-size: (24/16)rem
  font-style: italic
  font-weight: bold
  line-height: 1
  margin-bottom: 20px

.sw-poll-before__choice
  background-color: #fff
  border: 1px solid #c2c2c2
  border-radius: 3px
  padding: 5px 10px

.sw-poll-before__choice-head
  font-size: (11/16)rem
  font-weight: bold
  color: #666

.sw-poll-before__list
  list-style: none
  padding: 5px

.sw-poll-before__item
  border-bottom: 1px dotted #c1c1c1

  &:last-child
    border-bottom: 0

.sw-poll-before__radio
  display: none

.sw-poll-before__label
  line-height: 1
  display: inline-block
  font-weight: 300
  color: #333
  font-size: (18/16)rem
  cursor: pointer
  padding: 10px 0 10px 25px
  position: relative

  &::before
    content: ""
    position: absolute
    top: 50%
    left: 0
    transform: translateY(-50%)

    ctrl-radio-off()

  .sw-poll-before__radio:checked + &::before
    ctrl-radio-on()

.sw-poll-before__next
  text-align: center
  color: #999
  font-size: (14/16)rem
  cursor: pointer

  a
    text-decoration: none
    border-bottom: 1px dashed currentColor
    color: inherit

    &:hover
      border-bottom: none
