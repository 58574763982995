.sw-pin2pin
  margin-bottom: 10px
  position: relative
  padding: 10px 20px
  background-color: #f6f6f6
  border: 1px solid #d5d5d5
  border-radius: 5px

.sw-pin2pin__title
  font-size: (18/16)rem
  color: #a2a2a2
  margin-bottom: 15px
  text-transform: uppercase

.sw-pin2pin-stream
  width: 235px
  height: 180px

.sw-pin2pin-act
  color: #343434
  font-size: (14/16)rem
  font-weight: bold
  text-align: center
  line-height: 1
  margin: 19px 0

.sw-pin2pin-btns
  font-size: 0
  text-align: center

  &__sep
    display: inline-block
    vertical-align: top
    margin: 0 20px
    height: 63px
    width: 1px
    background-color: #b4b4b4

.sw-pin2pin-answer
.sw-pin2pin-decline
  display: inline-block
  vertical-align: top
  color: inherit
  text-decoration: none

  &__btn
    width: 70px
    height: 70px
    padding: 15px
    display: block
    border-radius: 50%
    .icon
      spritesmith($videochat_phone)

  &__icn
    width: 100%
    height: 100%
    display: block

  &__txt
    font-size: (13/16)rem
    color: #999
    text-align: center
    margin-top: 5px
    display: block
