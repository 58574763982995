.main-menu-user
  clearfix()
  display table
  margin: 30px 0 25px

  &__header
    width (100/4)%
    display table-cell
    vertical-align top
    position relative
    padding-top 6px
    font-weight 300
    color: #666

    &:after
      content ''
      display inline-block
      width 1px
      height 80px
      background-color #666666
      position absolute
      top 50%
      right 0
      transform translate(0, -50%)

  &__text
    display table-cell
    vertical-align top
    width (300/4)%
    padding 0 5px 0 20px
    color #666
    font-size (14/16)rem
    line-height 1.2
    font-weight: 300
