.sw-newsletter
  margin-bottom: 10px
  position: relative
  padding: 10px 20px
  background-color: #f6f6f6
  border: 1px solid #d5d5d5
  border-radius: 5px

.sw-newsletter__help
  width: 15px
  height: 15px
  padding: 3px
  position: absolute
  top: 3px
  right: 3px

  .icn-help-cont
    top: 15px
    right: 15px

.sw-newsletter__title
  font-size: (18/16)rem
  color: #a2a2a2
  margin-bottom: 15px
  text-transform: uppercase

.sw-newsletter__submit
  height: 35px
  font-size: (15/16)rem
  width: 100%
  border-radius: 5px
  margin: 15px 0 5px

.sw-newsletter__quest
  color: #47aca5
  font-size: (24/16)rem
  font-style: italic
  font-weight: bold
  line-height: 1
  margin-bottom: 15px

.sw-newsletter__desc
  color: #2d2d2d
  font-size: (12/16)rem
  line-height: 1.2
  margin-bottom: 15px

.sw-newsletter__email
  border: 1px solid #c2c2c2
  border-radius: 3px
  height: 35px
  background: #fff
  width: 100%
  box-shadow: none
  padding: 0 15px
  font-size: (14/16)rem

  &::placeholder
    color: #666
    font-style: italic
