.sw-contests
  margin-bottom: 10px
  position: relative
  padding: 10px 20px
  background-color: #f6f6f6
  border: 1px solid #d5d5d5
  border-radius: 5px

.sw-contests__help
  width: 15px
  height: 15px
  padding: 3px
  position: absolute
  top: 3px
  right: 3px

  .icn-help-cont
    top: 15px
    right: 15px

.sw-contests__title
  font-size: (18/16)rem
  color: #a2a2a2
  margin-bottom: 15px
  text-transform: uppercase

.sw-contests__quest
  color: #47aca5
  font-size: (24/16)rem
  font-style: italic
  font-weight: bold
  line-height: 1
  margin-bottom: 15px

.sw-contests__desc
  color: #2d2d2d
  font-size: (12/16)rem
  line-height: 1.2
  margin-bottom: 15px

.sw-contests-itm
  display: block
  overflow: hidden
  height: 140px

.sw-contests-itm__img
  display: block
  width: 100%
  height: 100%

.sw-contests-goto
  text-align: center
  color: #989898
  margin: 5px 0 3px

.sw-contests-goto__lnk
  color: inherit
  font-size: (14/16)rem
  text-decoration: none
  border-bottom: 1px dashed currentColor

  &:hover
    border-bottom: none
