.mm-user
  border: 1px solid #b5b5b5
  border-radius: 5px
  background-color: #fff
  padding: 10px

.mm-user-type
  color: #a4a4a4
  text-transform: uppercase
  text-align: center
  font-size: (18/16)rem
  font-weight: bold
  margin: 5px 0 10px

.mm-user-view
  position: relative

.mm-user-photo
  display: block
  height: 320px
  overflow: hidden

.mm-user-photo__img
  display: block
  width: 100%
  height: 100%

.mm-user-status
  position: absolute
  left: 10px
  bottom: 10px
  height: 14px
  padding: 0 10px
  text-transform: lowercase
  color: #fff
  border-radius: 3px
  font-size: (11/16)rem
  font-weight: 300

  &_on
    background-color: #95cd7d

  &_off
    background-color: #cfcfcf

.mm-user-bday
  position: absolute
  right: 54px
  bottom: -18px
  width: 40px
  height: 40px

.mm-user-info
  padding: 15px 0 10px

.mm-user-about
  display: table
  width: 100%

.mm-user-who
.mm-user-many
  display: table-cell
  vertical-align: middle

.mm-user-who__name
  display: block
  text-align: left
  line-height: 1
  font-size: 1rem
  color: #333
  font-weight: bold
  text-decoration: none

  nowrap()

  &:hover
    text-decoration: underline

.mm-user-who__loc
  color: #999
  text-align: left
  font-size: (12/16)rem
  line-height: 1
  margin-top: 10px

  nowrap()

.mm-user-many
  border-left: 1px solid #d3d3d3
  text-align: center
  width: 75px
  color: #47aba3
  font-weight: 300

.mm-user-many__cnt
  line-height: 1
  font-size: (22/16)rem

.mm-user-many__txt
  font-size: (10/16)rem
  line-height: 1
