.main-menu-new-wrap
  padding-top 20px
  background: linear-gradient(to bottom, #e0e0e0 0%, #fff 5px)
  position: relative // for custom scrollbar
  margin-bottom: 25px

  .custom-scroll_bar-x
    height: 6px
    bottom: -1px
    border-radius: 3px
    cursor: pointer
    background-color: #85bbb6
    opacity: .7

    &:hover
    &:active
      opacity: 1

.main-menu-new-header
  padding: 6px 0 17px 0

.main-menu-new-header__on-map
  font-size (24/16)rem
  color #666
  font-weight: 300
  display block
  text-transform: uppercase
  text-align: center

.main-menu-new-info
  padding: 6px 0 17px 0

  &-cont
    font-size: 0

  &__title
    font-size: (24/16)rem
    color: #666
    font-weight: 300
    display: inline-block
    vertical-align: middle
    text-transform: uppercase
    width: 155px

  &__desc
    font-size: (12/16)rem
    display: inline-block
    vertical-align: middle
    color: #999
    line-height: 1.2
    width: calc(100% - 155px)

.main-menu-new-info__on-map
  font-size (24/16)rem
  color #666
  font-weight: 300
  display block
  text-transform: uppercase
  text-align: center

.main-menu-new-action
  width: 210px
  height: 210px
  border-radius: 5px
  background-color: #f0f0f0
  color: #fff
  position: absolute
  z-index: 2
  left: 50%
  margin-left: -(@width / 2)
  top: 68px
  box-shadow: 0 6px 14px 4px rgba(#000, .3)
  padding: 6px
  text-decoration: none

  &:hover
    background-color: #fff

  &:hover::after
    border-top-color: #fff

  &:hover &-cont
    background-color: #2b8b82

  &:hover::before
    border-top-color: #2b8b82

  &::after
    content: ""
    display: block
    position: absolute
    bottom: -39px
    left: 0
    border: 105px solid transparent
    border-top-color: #f0f0f0
    border-top-width: 20px
    border-bottom-width: 20px
    border-radius: 5px

  &::before
    content: ""
    display: block
    position: absolute
    bottom: -33px
    left: 6px
    border: 100px solid transparent
    border-top-color: #29837b
    border-top-width: 20px
    border-bottom-width: 20px
    border-radius: 6px
    z-index: 1

  &-cont
    padding-top: 20px
    width: 100%
    height: 100%
    border-radius: 5px
    background-color: #29837b
    position: relative

  &__icn
    display: block
    width: 120px
    height: 125px
    stroke: #fff
    stroke-width: 1
    fill: #cacaca
    margin: 0 auto 10px

  &__title
    text-align: center
    text-transform: uppercase
    line-height: 1
    font-weight: bold

  &-help
    position: absolute
    top: 10px
    right: 10px
    width: 15px
    height: 15px
    padding: 3px
    border-radius: 50%

    .icn-help-cont
      top: 15px
      right: 15px

.main-menu-new-header-more
  text-decoration none
  color #666
  display: inline-block
  vertical-align: middle
  font-size: 0

  &:hover &__txt
    text-decoration: underline

  &__txt
    display: inline
    vertical-align: middle
    font-size: (14/16)rem

  &__icn
    width: 10px
    transform: rotate(-90deg)
    height: 10px
    fill: currentColor
    display: inline-block
    vertical-align: middle
    margin-left: 5px

.main-menu-new-header__add-place
  padding 0 30px
  line-height 35px
  display inline-block
  border-radius: 2px
  float right
  text-decoration none

.main-menu-new-gallery
  height 180px
  white-space: nowrap
  margin-bottom: 50px

.main-menu-new-gallery-item
  width 180px
  height 180px
  overflow hidden
  display inline-block

.main-menu-new-scroll
  padding: 0 30px

.main-menu-new-scroll__path
  height 3px
  background-color: #d9d9d9
  border-radius: 2px
