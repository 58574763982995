.sw-wantgo
  margin-bottom: 10px
  position: relative
  padding: 10px 20px
  background-color: #f6f6f6
  border: 1px solid #d5d5d5
  border-radius: 5px

.sw-wantgo__title
  font-size: (18/16)rem
  color: #a2a2a2
  margin-bottom: 15px
  text-transform: uppercase

.sw-wantgo-who
  font-size: 0

.sw-wantgo-thumb
.sw-wantgo-info
  display: inline-block
  vertical-align: top
  font-size: 1rem

.sw-wantgo-thumb
  width: calc(100% - 130px)

.sw-wantgo-info
  padding-left: 15px
  width: 130px

.sw-wantgo-ava
  height: 135px
  border: 1px solid #b3b3b3
  padding: 5px

  &__img
    display: block
    width: 100%
    height: 100%

.sw-wantgo-name
  line-height: 1

  nowrap()

  &__lnk
    font-size: (14/16)rem
    color: #343434
    text-decoration: none
    font-weight: bold

    &:hover
      text-decoration: underline

.sw-wantgo-status
  width: 55px
  height: 15px
  line-height: @height
  border-radius: 3px
  margin: 15px 0
  font-size: (11/16)rem
  text-align: center
  font-weight: 300
  text-transform: lowercase
  color: #fff

  &_on
    background-color: #95cd7d

  &_off
    background-color: #bababa

.sw-wantgo-about
  color: #999
  font-size: (12/16)rem
  line-height: 1.2
  height: 70px
  overflow: hidden

  a
    color: inherit
    text-decoration: underline

    &:hover
      text-decoration: none

.sw-wantgo-tip
  background-color: #fdfdee
  padding: 10px 15px
  font-style: italic
  font-size: (12/16)rem
  color: #787878
  position: relative
  border: 1px solid #b4b4b4
  border-radius: 3px
  margin: 15px 0

  &::after
    content: ""
    background-color: inherit
    transform: rotate(45deg)
    width: 30px
    height: 30px
    border-top: inherit
    border-left: inherit
    position: absolute
    top: -15px
    left: 35px

  &__cont
    height: 55px
    background-color: inherit
    line-height: 1.2
    position: relative
    overflow: hidden
    z-index: $zi-sw-wantgo-tip-cont

    a
      color: #47aca5
      text-decoration: underline

      &:hover
        text-decoration: none

.sw-wantgo-btn
  margin: 25px 0 15px

.sw-wantgo-another
  color: #999
  font-size: (13/16)rem
  text-align: center

  &__lnk
    color: inherit
    text-decoration: underline

    &:hover
      text-decoration: none
