.footer
  height: 40px
  background-color: $bg-header

.footer-content
  height: 100%
  color: #fff
  position: relative

.footer_is-dialog
  display: none

.footer-copy-wrap
  height: 100%
  display: table
  position: absolute
  left: 0
  top: 0

.footer-copy
  font-size: 0
  display: table-cell
  vertical-align: middle
  list-style: none

.footer-copy__year
.footer-copy__title
.footer-copy__right
  display: inline-block
  font-size: (12/16)rem
  border-right: 1px solid currentColor
  padding: 0 6px

.footer-copy__year
  padding-left: 11px

.footer-copy__right
  border-right: none

.footer-menu-wrap
  display: table
  width: 100%
  height: 100%

.footer-menu
  display: table-cell
  text-align: center
  vertical-align: middle
  margin: 0 auto
  list-style: none
  font-size: 0

.footer-menu__item
  display: inline-block
  font-size: (12/16)rem
  border-right: 1px solid currentColor
  padding: 0 6px

  &:last-child
    border-right: none

.footer-menu__link
  color: inherit
  text-decoration: none
  display: block

  &:hover
    text-decoration: underline

.footer-social-wrap
  display: table
  height: 100%
  position: absolute
  top: 0
  right: 0

.footer-social
  display: table-cell
  vertical-align: middle

.footer-social-txt
  display: inline-block
  font-size: (12/16)rem
  margin-right: 15px

.footer-social-list
  display: inline-block
  vertical-align: middle
  font-size: 0
  list-style: none

.footer-social-list__item
  font-size: (12/16)rem
  display: inline-block
  margin: 0 4px
  opacity: .7

  &:hover
    opacity: 1

  &:last-child
    margin-right: 11px

.footer-social-list__link
  display: block
  width: 30px
  height: 30px

.footer-social-list__icn
  width: 100%
  height: 100%
  fill: #fff
  display: block
  padding: 2px

  .footer-social-list__item_fb &
    padding: 0
