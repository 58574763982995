.mm-search
  background #308079
  background linear-gradient(to bottom, #308079 0%, #53a7a0 100%)
  box-shadow inset 0px 0px 3px 0px rgba(#000, .49)
  border-radius 3px
  padding 13px 9px 15px

.mm-search-head
  padding: 0 2px 0 5px
  margin-bottom: 10px

  clearfix()

  &-lft
    padding-top: 6px
    float: left

  &-rgt
    float: right

  &__title
    font-size: (24/16)rem
    line-height: 1
    font-weight: 300
    color: #fff

  &-type
    display: block
    list-style: none
    font-size: 0

    &-itm
      display: inline-block
      vertical-align: middle
      color: #9bbfbc
      margin-left: 5px
      border-radius: 4px

      &_active
      &:hover
        color: #fff

      &_active
        background-color: #2d756f

      &__lnk
        display: block
        padding: 12px
        line-height: 1
        text-transform: uppercase
        color: inherit
        text-decoration: none
        font-weight: bold
        font-size: (14/16)rem

.mm-search-filter
  clearfix()

  &-lft
    float: left
    width: calc(100% - 140px)

  &-rgt
    float: right
    width: 120px

  &-btn
    width: 100%
    height: 44px
    display: block
    border-radius: 3px
    text-align: center
    text-transform: uppercase
    font-size: 1rem
    font-weight: bold
    line-height: (@height - 2px)

  &-opts
    width: 100%
    display: table
    position: relative

  &-m-sex
  &-m-place
  &-m-city
  &-m-date
  &-m-more
  &-pl-sex
  &-pl-age
  &-pl-city
  &-pl-goal
  &-pl-more
  &-vc-sex
  &-vc-age
  &-vc-city
  &-vc-goal
  &-vc-more
  &-p-type
  &-p-city
  &-p-place
  &-p-price-from
  &-p-price-to
  &-p-more
  &-pub-type
  &-pub-city
  &-pub-place
  &-pub-price-from
  &-pub-price-to
  &-pub-more
    display: table-cell
    vertical-align: top

    &__select
      width: 100%
      height: 44px
      appearance: none
      border: 1px solid #959595
      border-right: none
      font-size: (12/16)rem
      color: #666
      padding: 0 20px
      background: url("/arrow-down-gray.svg#base64") no-repeat 95% 50% #fff

      &:hover
        background-color: #f6f6f6

    .chosen-container
      background-color: #fff
      border: 1px solid #959595
      border-right: none
      position: relative
      height: 44px
      font-size: (14/16)rem
      color: #666
      width: 100% !important

      &:hover .chosen-single
        background-color: #f6f6f6

    .chosen-drop
      background-color: #fff
      border: 1px solid #959595
      border-radius: 3px

    .chosen-single
      display: block
      height: 100%
      line-height: 42px
      padding-left: 20px

      nowrap()

    div b
      width: 20px
      background-image: url("/arrow-down-gray.svg#base64")
      background-repeat: no-repeat
      background-position: center left

    .chosen-with-drop div b
      background-image: url("/arrow-left-gray.svg#base64")

    .chosen-search
      border-bottom: 1px solid #959595

    .chosen-results
      li
        border-bottom: inherit
        padding: 5px 20px

      li:last-child
        border-bottom: 0
        border-radius:  0 0 3px 3px

      li.highlighted
        background-color: #f6f6f6

      .disabled-result
        color: #999

  &-m-sex
  &-pl-sex
  &-vc-sex
  &-p-type
  &-pub-type
    .chosen-container
    .chosen-single
    &__select
      border-radius: 3px 0 0 3px

  &-p-place
  &-pub-place
    &__input
      display: block
      width: 100%
      background: #fff
      border: 1px solid #959595
      border-right: none
      height: 44px
      line-height: (@height - 2px)
      font-size: (14/16)rem
      color: #666
      padding: 0 20px

      &:hover
        background: #f6f6f6

      &::placeholder
        color: inherit

  &-p-type
    width: 135px

  &-p-city
    width: 210px

  &-p-place
    width: 255px

  &-p-price-from
    width: 125px

  &-p-price-to
    width: 130px

  &-pub-type
    width: 135px

  &-pub-city
    width: 210px

  &-pub-place
    width: 255px

  &-pub-price-from
    width: 125px

  &-pub-price-to
    width: 130px

  &-pl-sex
    width: 140px

  &-pl-age
    width: 212px

  &-pl-city
    width: 255px

  &-pl-goal
    width: 255px

  &-vc-sex
    width: 140px

  &-vc-age
    width: 212px

  &-vc-city
    width: 255px

  &-vc-goal
    width: 255px

  &-m-sex
    width: 135px

  &-m-place
    width: 210px

  &-m-city
    width: 255px

  &-m-date
    width: 255px

  &-m-more
  &-p-more
  &-pub-more
  &-pl-more
  &-vc-more
    border: 1px solid #959595
    border-radius: 0 3px 3px 0
    background-color: #fff

    &:hover &-label
      background-color: #f6f6f6

    &-label
      display: block
      font-size: 0
      border-radius: inherit
      height: 42px
      line-height: @height
      text-align: center
      cursor: pointer

      &__txt
        color: #666
        font-size: (14/16)rem
        vertical-align: middle

      &__icn
        width: 20px
        height: @width
        display: inline-block
        vertical-align: middle
        margin-left: 40px
        fill: #666

    &-drop
      position: absolute
      top: 100%
      right: 0
      width: 100%
      background-color: #fff
      border: 1px solid #959595
      border-top: 0
      padding: 10px 20px
      z-index: 1
      border-radius: 3px

.mm-search-fields
  font-size: 0

.mm-search-query
  display: inline-block
  vertical-align: top
  font-size: 1rem

.mm-search-query
  width: 100%
  height: 45px
  position: relative

  &__input
    width: 100%
    height: 100%
    border-radius: 3px
    background-color: #fff
    border: 1px solid #959595
    padding: 0 50px
    line-height: 22px

    &::placeholder
      font-style: italic
      font-size: (12/16)rem
      color: #999

  &__submit
    position: absolute
    top: 0
    right: 0
    width: 24px
    height: 24px
    background: url("/search-glass.svg#base64") center no-repeat transparent
    border: none
    box-shadow: none
    top: 50%
    margin-top: -(@height / 2)
    right 13px
    opacity: .7

    &:hover
      opacity: 1

.mm-search-query-mic
  width: 17px
  display: block
  height: 29px
  position: absolute
  left: 13px
  top: 50%
  color: #b2b3b3
  text-decoration: none
  margin-top: -(@height / 2)

  &:hover
    color: #808080

  &__icn
    fill: currentColor
    display: block
    width: 100%
    height: 100%

.mm-search-params
  margin-top: 10px

  clearfix()

.mm-search-help
  font-size: (12/16)rem
  float: left
  padding-left: 20px
  position: relative

  &__txt
    color: #fff
    text-shadow: 0px 1px 1px rgba(#000, .7)
    display: inline
    vertical-align: middle

  .icn-help-cont
    top: 15px
    left: 15px

.mm-search-help-show
  display inline-block
  vertical-align: middle
  text-shadow: 0px 1px 1px rgba(#000, .7)
  color #b1e47f
  text-decoration: none
  margin-left: 5px

  &__icn
    display: inline-block
    vertical-align: middle
    position: relative
    top: 1px
    width: 10px
    height: 10px
    fill: currentColor

  &__txt
    display: inline
    vertical-align: middle
    text-decoration: underline

    ^[0]:hover &
      text-decoration: none

.mm-search-help-tip
  position: absolute
  top: 0
  left: 0
  width: 15px
  height: 15px
  padding: 3px
  border-radius: 50%

.mm-search-attr
  float: right

for num in 1 2 3
  .mm-search-attr-itm
    text-align: right
    display: inline-block
    margin-left: 15px

    &__checkbox-{num}
      display: none

    &__label-{num}
      color: #fff
      font-size: (12/16)rem
      padding-left: 25px
      cursor: pointer
      position: relative

      &:before
        content: ""
        top: -3px
        left: 0
        position: absolute

        ctrl-checkbox-off()

    &__checkbox-{num}:checked + &__label-{num}::before
      ctrl-checkbox-on()
