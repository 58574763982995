.custom-scroll_container
	overflow: hidden !important

.custom-scroll_inner
	overflow: scroll
	height: 100%

.custom-scroll_inner::-webkit-scrollbar
	width: 0
	height: 0

.custom-scroll_inner
	-ms-overflow-style: none

.custom-scroll_bar-y
.custom-scroll_bar-x
	cursor: default
	position: absolute
	border-radius: 3px
	z-index: $zi-custom-scroll
	background: black
	opacity: .4
	user-select: none

	transition: opacity .2s;

.custom-scroll_bar-y
	top: 0
	right: 1px
	width: 8px

.custom-scroll_bar-x
	left: 0
	bottom: 1px
	height: 8px

.custom-scroll_bar-y:hover
.custom-scroll_bar-x:hover
	opacity: .7

.custom-scroll_bar-y:active
.custom-scroll_bar-x:active
	opacity: 1

.custom-scroll_hidden-y > .custom-scroll_bar-y
.custom-scroll_hidden-x > .custom-scroll_bar-x
	opacity: 0
	visibility: hidden
	transition: opacity .2s, visibility step-end .3s
