.main-menu-map
  background #ffcb80
  padding-top 340px
  box-shadow: inset 0px 50px 50px -50px rgba(0,0,0,0.33),
              inset 0px -50px 50px -50px rgba(0,0,0,0.33)

  &__table
    display table

  &__right
  &__left
    width 50%
    display table-cell
    vertical-align middle

  &__left
    background-color #fff
    padding 29px 43px

  &__right
    background-color rgba(255,255,255,0.8)
    text-align center

  &__icon
    width 200px
    height 185px
    display inline-block

  &__header
    font-size (32/16)rem
    color #666
    font-weight 300
    margin-bottom 11px
    letter-spacing: 0.6px

  &__text
    color #666
    margin-bottom 34px
    line-height 1.2

.main-menu-map-agree
  height 45px
  line-height (@height - 2px)
  width 100%
  display block
  border-radius 3px
  font-size: 0

  &__txt
    font-size (14/16)rem
    display: inline
    vertical-align: middle
    font-weight normal

  &__icn
    display: inline-block
    vertical-align: middle
    width: 22px
    height: 22px
    fill: #fff
    filter: drop-shadow(0 1px 1px rgba(#000, .3))
    margin-left: 10px
