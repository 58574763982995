.mm-gal-people
  display: block
  width: 100%
  height: 100%
  position: relative
  color: inherit
  text-decoration: none

  &__img
    display: block
    width: 100%
    height: 100%

    ^[0]_locked &
      filter: blur(10px)

.mm-gal-people-over
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  background: url("../img/bg/profile-bg-locked.png") repeat center transparent

.mm-gal-people-lock
  width: 45px
  height: 65px
  position: absolute
  top: 50%
  left: 50%
  margin-left: -(@width / 2)
  margin-top: -(@height / 2)
  white-space: normal
  z-index: $zi-people-lock

  &__icn
    display: block
    width: 100%
    height: 100%
    fill: rgba(#fff, .7)
    stroke: rgba(#000, .1)
    stroke-width: 3
    filter: drop-shadow(0 1px 1px rgba(#000, .3))

.mm-gal-people-tip
  position: absolute
  bottom: -10px
  right: -10px
  width: 15px
  height: 15px
  padding: 3px
  border-radius: 50%

  .icn-help-cont
    bottom: 13px
    right: 13px

.mm-gal-people-state
  &__status
    display: inline-block
    height: 14px
    padding: 0 10px
    text-transform: lowercase
    color: #fff
    border-radius: 3px
    font-size: (11/16)rem
    font-weight: 300

    &_on
      background-color: #95cd7d

    &_off
      background-color: #cfcfcf

.mm-gal-people-info
  position: absolute
  bottom: 0
  left: 0
  width: 100%
  height: 90px
  padding: 38px 15px 0
  background: linear-gradient(to bottom, transparent 0%, #000 100%)
  opacity: .75
  transition: opacity .3s 0s
  white-space: normal

  .mm-gal-people_locked &
    opacity: 1

  .mm-gal-people:hover &
    opacity: 1

.mm-gal-people-who
  margin-top: 2px
  position: relative
  display: inline-block
  color: #e9e9e9
  font-size: (15/16)rem

  &__name
    display: inline-block
    vertical-align: middle
    max-width: 95px

    nowrap()

    &::after
      content: ", "
      margin-right: 3px

  &__age
    display: inline-block
    vertical-align: middle

  &__flirt
  &__serious
    width: 20px
    height: 15px
    display: inline-block
    vertical-align: middle
    position: relative
    top: -7px
    fill: #fff
