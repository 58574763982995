.sw-chats
  margin-bottom: 10px
  position: relative
  padding: 10px 20px
  background-color: #f6f6f6
  border: 1px solid #d5d5d5
  border-radius: 5px

.sw-chats__title
  font-size: (18/16)rem
  color: #a2a2a2
  margin-bottom: 15px
  text-transform: uppercase

.sw-chats-room
  display: block
  height: 260px

.sw-chats-room-itm
  display: table
  width: 100%

.sw-chats-room-ava
.sw-chats-room-cont
  display: table-cell
  vertical-align: top

.sw-chats-room-ava
  width: 35px

.sw-chats-room-cont
  padding-left: 10px

.sw-chats-who
  display: block
  height: 35px

  &__img
    width: 100%
    height: 100%
    border-radius: 3px

.sw-chats-msg
  padding: 10px
  border-radius: 3px
  border: 1px solid transparent
  font-size: (11/16)rem
  color: #666
  line-height: 1.2
  margin-bottom: 15px
  position: relative

  &::before
    content: ""
    border-top: inherit
    border-left: inherit
    position: absolute
    width: 10px
    height: 10px
    top: 11px
    left: -6px
    transform: rotate(-45deg)
    background-color: inherit

  &_me
    border-color: #d7d7d7
    background-color: #fff

  &_you
    background-color: #e1eded

.sw-chats-btns
  font-size: 0
  margin: 20px 0 10px

  &__write
  &__new
    display: inline-block
    vertical-align: middle
    border-radius: 3px
    font-size: (12/16)rem

  &__write
    width: calc(100% - 90px)
    height: 35px
    margin-right: 5px
    line-height: (@height - 2px)

  &__new
    height: 35px
    width: 85px
    background-color: #d5d5d5
    line-height: @height
    color: #666
    text-decoration: none
    text-align: center
    position: relative

    &::after
      content: ""
      position: absolute
      top: 50%
      margin-top: -5px
      right: -10px
      border: 5px solid transparent
      border-left-color: #d5d5d5

    &:hover
      background-color: #bdb9b9

    &:hover::after
      border-left-color: #bdb9b9
