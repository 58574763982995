.sw-sympathy
  margin-bottom: 10px
  position: relative
  padding: 10px 20px
  background-color: #f6f6f6
  border: 1px solid #d5d5d5
  border-radius: 5px

.sw-sympathy__title
  font-size: (18/16)rem
  color: #a2a2a2
  margin-bottom: 15px
  text-transform: uppercase

.sw-sympathy-who
  display: block
  height: 260px

  &__img
    display: block
    width: 100%
    height: 100%

.sw-sympathy-btns
  font-size: 0
  margin: 20px 0 10px

  &__express
  &__next
    display: inline-block
    vertical-align: middle
    border-radius: 3px
    font-size: (12/16)rem

  &__express
    width: 165px
    height: 35px
    margin-right: 5px
    line-height: (@height - 2px)

  &__next
    height: 35px
    background-color: #d5d5d5
    line-height: @height
    color: #666
    width: calc(100% - 170px)
    text-decoration: none
    text-align: center
    position: relative

    &::after
      content: ""
      position: absolute
      top: 50%
      margin-top: -5px
      right: -10px
      border: 5px solid transparent
      border-left-color: #d5d5d5

    &:hover
      background-color: #bdb9b9

    &:hover::after
      border-left-color: #bdb9b9
