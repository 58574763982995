.nav-side
  width: 100%
  height: 100%
  display: flex
  flex-direction: column
  justify-content: space-between
  align-items: stretch
  background-color: #f4f4f4

.nav-side-top-my-info
  color: #fff
  background-color: #9ccd6a
  font-size: (14/16)rem
  font-weight: bold
  text-transform: uppercase
  text-align: center
  border: 1px solid #3b871b
  border-radius: 5px
  width: 90%
  margin: 15px auto 20px auto
  padding: 10px 20px
  position: relative

  &::after
    content: ""
    position: absolute
    width: 15px
    height: 15px
    bottom: -8px
    left: 50%
    transform: rotate(45deg)
    border: inherit
    border-top-color: transparent
    border-left-color: transparent
    margin-left: -(@width / 2)
    background-color: inherit

.nav-side-top-my-info__help
  position: absolute
  top: -10px
  right: -10px
  width: 15px
  height: 15px
  border-radius: 50%
  padding: 3px

  .icn-help-cont
    right: 15px
    top: 15px

.nav-side-top-tape
  margin: 15px auto 0
  width: 90%

.nav-side-top-tape-btn
  display: block
  position: relative
  border-radius: 5px
  background-color: #cfcfcf
  border: 1px solid #a9a9a9
  text-decoration: none
  color: #fff
  padding: 6px 0
  font-size: (18/16)rem
  font-weight: bold
  text-transform: uppercase
  text-align: center

.nav-side-top-my-info__txt
.nav-side-top-tape-btn__txt
  text-shadow: 1px 1px 1px rgba(50, 50, 50, .5);

.nav-side-top-tape-btn__help
  position: absolute
  top: -10px
  right: -10px
  width: 15px
  height: 15px
  border-radius: 50%
  padding: 3px

  .icn-help-cont
    right: 15px
    top: 15px

.nav-side-top-tape-desc
  color: #666
  font-size: (12/16)rem
  padding: 10px 20px 0

.my-side-menu
  list-style: none
  font-size: (13/16)rem

.my-side-menu__item
  border-bottom: 1px solid #cfcfcf
  height: 30px
  position: relative
  cursor: pointer
  color: #666

.my-side-menu__item:hover
.my-side-menu__item_active
  background-color: #69bdb7
  background: linear-gradient(to bottom, #69bdb7 0%,#65b5ad 50%,#5eaba4 100%)
  color: #fff

.my-side-menu__edit
  fill: #447b76
  position: absolute
  top: 7px
  right: 25px
  cursor: pointer
  height: 17px
  width: 17px

.my-side-menu__link
  display: block
  color: inherit
  height: 100%
  text-decoration: none

.my-side-menu__label
  padding: 0 40px 0 30px
  line-height: 29px

  nowrap()

.my-side-menu__icon
  height: 19px
  width: 19px
  position: absolute
  top: 50%
  margin-top: -(@height / 2)
  fill: currentColor
  left: 25px

.my-side-menu__count
  background-color: #4594a2
  color: white
  min-width: 20px
  height: 20px
  line-height: (@height)
  padding: 0 3px
  border-radius: 3px
  text-align: center
  font-size: (9/16)rem
  position: absolute
  top: 50%
  margin-top: -(@height / 2)
  right: 20px

.setting-menu
  color: #666
  font-size: (13/16)rem
  list-style: none
  margin-top: 10px

.setting-menu__item
  border-top: 1px solid #cfcfcf
  height: 40px
  position: relative

.setting-menu__item:hover
.setting-menu__item_active
  color: #69bdb7

.setting-menu__link
  text-decoration: none
  display: block
  color: inherit
  height: 100%

.setting-menu__label
  padding: 0 5px 0 50px
  line-height: 39px

  nowrap()

.setting-menu__icon
  height: 19px
  width: 19px
  position: absolute
  top: 50%
  margin-top: -(@height / 2)
  fill: #a7a2a2
  left: 20px
