.main-menu-top
  border-top 15px solid #3b8e86
  min-height 10px
  background: url("../img/bg/profile-bg-locked.png") repeat center #4fa39c

  &_bb
    border-bottom 35px solid #3b8e86

  &-head
    margin: 30px 0
    clearfix()

    &__title
      float: left
      font-size (34/16)rem
      font-weight 300
      color #fff
      text-align center
      text-transform uppercase
      letter-spacing -0.5px
      line-height: 1

    &__btn
      float: right
      width: 240px
      height: 35px
      line-height: (@height - 2px)
      border-radius: 3px
      display: block
      font-size (14/16)rem
      font-weight bold

  &-lst
    padding-bottom 20px
    display: block
    text-align: center

    &:after
      width (10*100/12)%
      height 4px
      background-color rgba(#fff, .2)
      display block
      content ''
      margin 0 auto
      position relative
      top: 22px

  &-cell
    width 300px
    vertical-align top
    padding: 0 15px
    margin-bottom: 15px
    display inline-block

  &-type
    color: #fff
    text-transform: uppercase
    font-weight: bold
    font-size: 1rem
    margin-bottom: 25px

  &-itm
    text-align: left
