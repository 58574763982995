.mm-now-video
  font-size: 0

.mm-now-video-poster
.mm-now-video-info
  display: inline-block
  vertical-align: top
  font-size: 1rem

.mm-now-video-poster
  width: 90px

  &__lnk
    display: block
    height: 50px

  &__img
    display: block
    width: 100%
    height: 100%

.mm-now-video-info
  width: calc(100% - 90px)
  padding-left: 15px

.mm-now-video-name
  line-height: 1

  nowrap()

  &__lnk
    font-size: (14/16)rem
    color: #2d2d2d
    text-decoration: none
    font-weight: bold

    &:hover
      text-decoration: underline

.mm-now-video-adds
  font-size: 0
  display: block
  list-style: none
  margin: 15px -10px 0

.mm-now-video-adds-cell
  border-right: 1px solid #4594a2
  padding: 0 10px
  display: inline-block
  vertical-align: top

  &:last-child
    border-right: none

.mm-now-video-adds-itm
  display: block
  color: #4594a2
  text-decoration: none

  &__icn
  &__txt
    display: inline-block
    vertical-align: middle

  &__icn
    width: 19px
    height: 17px
    fill: currentColor
    margin-right: 5px

  &__txt
    font-size: (11/16)rem
    line-height: 1

    .mm-now-video-adds-itm:hover &
      text-decoration: underline
