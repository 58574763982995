.mm-qnaire
  display: block
  color: inherit
  text-decoration: none
  border-radius: 3px
  overflow: hidden
  position: relative
  margin-bottom: 12px

  &_small
    height: 180px

  &_big
    height: 372px

  &__img
    display: block
    width: 100%
    height: 100%

    .mm-qnaire_locked &
      filter: blur(10px)

.mm-qnaire-who
  position: absolute
  left: 0
  bottom: 0
  padding: 0 10px 10px
  color: #fff
  font-size: 0

  .mm-qnaire:hover &
    visibility: hidden

  .mm-qnaire_locked:hover &
    visibility: visible

  &__name
    font-size: (14/16)rem
    line-height: 1
    display: inline-block
    vertical-align: middle

    nowrap()

    .mm-qnaire_small &
      max-width: 100px

    .mm-qnaire_big &
      max-width: 210px

    &::after
      content: ","
      margin-right: 5px

  &__age
    font-size: (14/16)rem
    line-height: 1
    display: inline-block
    vertical-align: middle

.mm-qnaire-bday
  position: absolute
  right: 10px
  bottom: 10px
  width: 34px
  height: 34px

  &__icn
    display: block
    width: 100%
    height: 100%

  .mm-qnaire:hover &
    visibility: hidden

  .mm-qnaire_locked:hover &
    visibility: visible

.mm-qnaire-shadow
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  background-color: rgba(#000, .7)
  color: #fff
  padding: 10px
  visibility: hidden

  .mm-qnaire:hover &
    visibility: visible

.mm-qnaire-author
  font-size: 0
  line-height: 1

  &__name
  &__age
    display: inline-block
    vertical-align: middle
    font-size: (18/16)rem

  &__name
    nowrap()

    &::after
      content: ","
      margin-right: 5px

    .mm-qnaire_small &
      max-width: 130px

    .mm-qnaire_big &
      max-width: 310px

.mm-qnaire-loc
  line-height: 1
  margin: 5px 0 20px
  font-size: (14/16)rem
  max-width: 100%

  nowrap()

.mm-qnaire-about
  font-size: (12/16)rem
  line-height: 1.2

.mm-qnaire-over
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  background: url("../img/bg/profile-bg-locked.png") repeat center transparent

.mm-qnaire-lock
  width: 45px
  height: 65px
  position: absolute
  top: 50%
  left: 50%
  margin-left: -(@width / 2)
  margin-top: -(@height / 2)

  &__icn
    display: block
    width: 100%
    height: 100%
    fill: rgba(#fff, .7)
    stroke: rgba(#000, .1)
    stroke-width: 3
    filter: drop-shadow(0 1px 1px rgba(#000, .3))

.mm-qnaire-hint
  position: absolute
  bottom: -10px
  right: -10px
  width: 15px
  height: 15px
  padding: 3px
  border-radius: 50%

  .icn-help-cont
    bottom: 13px
    right: 13px

.mm-qnaire-near
  position: absolute
  right: 10px
  bottom: 10px
  color: #fff

  &__txt
    margin: 0 auto
    font-size: (11/16)rem
    text-transform: uppercase
    line-height: 1
    border: 1px solid #717172
    border-radius: 3px
    background-color: rgba(#000, .5)
    padding-top: 7px
    width: 105px
    height: 36px
    text-align: center

  &__cnt
    font-size: 0
    font-weight: 300
    line-height: 1
    text-align: center

  &__num
    font-size: (80/16)rem

  &__per
    font-size: (38/16)rem

  .mm-qnaire:hover &
    visibility: hidden

.mm-qnaire-match
  position: absolute
  right: 10px
  top: 10px
  color: #fff

  &__txt
    margin: 0 auto
    font-size: (11/16)rem
    text-transform: uppercase
    line-height: 1
    border: 1px solid #717172
    border-radius: 3px
    background-color: rgba(#000, .5)
    padding-top: 7px
    width: 105px
    height: 36px
    text-align: center

    span
      font-size: (9/16)rem

  &__cnt
    font-size: 0
    font-weight: 300
    line-height: 1
    text-align: center

  &__num
    font-size: (80/16)rem

  &__per
    font-size: (38/16)rem

  .mm-qnaire:hover &
    visibility: hidden

.mm-qnaire-compare
  position: absolute
  left: 10px
  top: 10px
  color: #fff

  &__txt
    font-size: (11/16)rem
    text-transform: uppercase
    line-height: 1
    border: 1px solid #717172
    border-radius: 3px
    background-color: rgba(#000, .5)
    padding-top: 7px
    width: 105px
    height: 36px
    text-align: center
    margin: 0 auto

  &__cnt
    font-size: (20/16)rem
    font-weight: bold
    text-align: center
    width: 60px
    height: 60px
    line-height: @height
    background: url("../img/main/matches-cloud.png") no-repeat center
    background-size: contain
    margin: 0 auto 5px

  .mm-qnaire:hover &
    visibility: hidden
