.main-menu-gallery
  margin-bottom: 15px

  [class^="main-menu-gallery__plate"]
    margin-bottom 12px
    background-size cover
    background-position center
    background-repeat no-repeat
    position relative
    display: block
    border-radius: 3px
    overflow: hidden

  &__plate_small
    height 180px

    .main-menu-gallery__info
      max-height 170px

  &__img
    display: block
    width: 100%
    height: 100%

  &__plate_big
    height 372px

    .main-menu-gallery__info
      max-height 362px

  &__shadow
    position absolute
    width 100%
    top 0
    bottom 0
    background-color rgba(0,0,0,0.3)

  &__info
    position absolute
    bottom 0
    padding 10px 10px 0 10px
    margin-bottom 10px
    color #fff
    max-height inherit
    overflow hidden

  &__name
    margin-bottom 10px
    font-size (14/16)rem

  &__desc
    display none
    opacity 0
    font-size (12/16)rem

  [class^="main-menu-gallery__plate"]:hover

    .main-menu-gallery__shadow
      background-color rgba(0,0,0,0.7)

    .main-menu-gallery__desc
      display block
      opacity 1

    .main-menu-gallery__name
      font-size (18/16)rem
