.people-now-wrap
  background-color: #fafafa

.people-now
  padding: 30px 0

  &__title
    line-height: 1
    font-size: (33/16)rem
    font-weight: 300
    color: #666
    margin-bottom: 50px

.people-now-layout
  display: table
  width: 100%

.people-now-layout-lft
.people-now-layout-rgt
  display: table-cell
  vertical-align: top

.people-now-layout-rgt
  width: 420px
  padding-left: 45px

.people-now-news
  padding-bottom: 25px
  margin-bottom: 25px
  font-size: 0
  border-bottom: 1px dotted #c1c1c1

  &:last-child
    border-bottom: none

.people-now-news-lft
.people-now-news-rgt
  display: inline-block
  vertical-align: top
  font-size: 1rem

.people-now-news-lft
  width: 95px

.people-now-news-rgt
  width: calc(100% - 95px)
  padding-left: 15px

.people-now-ava
  display: block
  height: 95px

  &__img
    display: block
    width: 100%
    height: 100%
    border-radius: 3px

.people-now-username
  line-height: 1

  nowrap()

  &__lnk
    color: #343434
    font-size: 1rem
    text-decoration: none
    font-weight: bold

    &:hover
      text-decoration: underline

.people-now-action
  font-size: (14/16)rem
  color: #999
  line-height: 1
  margin: 5px 0 10px

  nowrap()

.people-now-mode
  font-size: (12/16)rem
  display: block
  font-weight: normal
  border-radius: 3px
  height: 35px
  width: 218px
  line-height: (@height - 2px)
