.main-menu-search-header
  padding 22px 0 5px 0
  font-size (30/16)rem
  text-align: center
  font-weight 300
  color #666
  text-transform: uppercase

.main-menu-search-wrap
  margin-bottom: 40px
