.mm-now-photos
  display: block
  list-style: none
  font-size: 0

.mm-now-photo
  display: inline-block
  vertical-align: top
  margin-right: 5px

  &__lnk
    display: block
    width: 75px
    height: 50px

  &__img
    display: block
    width: 100%
    height: 100%

  &:last-child
    margin-right: 0
