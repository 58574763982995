.mm-born
  background-color: #f7f7f7
  border: 1px solid #d5d5d5
  border-radius: 5px
  position: relative
  padding: 10px 20px

  &__title
    text-transform: uppercase
    color: #a2a2a2
    line-height: 1
    font-weight: bold
    font-size: (18/16)rem
    margin-bottom: 15px

.mm-born-hint
  position: absolute
  top: 3px
  right: 3px
  width: 15px
  height: 15px
  padding: 3px
  border-radius: 50%

  .icn-help-cont
    top: 15px
    right: 15px

.mm-born-type
  width: 40px
  height: 40px
  position: absolute
  top: -20px
  right: 20px

.mm-born-cnt
  color: #57aebe
  font-weight: 300
  text-align: center
  margin-bottom: 10px

  &__num
    line-height: 1
    font-size: (70/16)rem

  &__txt
    font-size: (18/16)rem
    line-height: 1
    text-transform: uppercase

.mm-born-people
  display: block
  list-style: none
  font-size: 0
  margin: 0 -3px

.mm-born-people-cell
  display: inline-block
  vertical-align: top
  padding: 0 3px
  margin-bottom: 5px
  width: 25%
  font-size: 1rem

.mm-born-people-itm
  display: block
  height: 75px
  width: 100%

  &__img
    display: block
    width: 100%
    height: 100%
    border-radius: 3px

.mm-born-all
  text-align: center
  color: #999
  padding: 10px 0

  &__lnk
    font-size: (13/16)rem
    color: inherit
    line-height: 1
    text-decoration: underline

    &:hover
      text-decoration: none
