.icon {
  display: inline-block;
  vertical-align: top;
}
.main-menu-new-wrap {
  padding-top: 20px;
  background: linear-gradient(to bottom, #e0e0e0 0%, #fff 5px);
  position: relative;
  margin-bottom: 25px;
}
.main-menu-new-wrap .custom-scroll_bar-x {
  height: 6px;
  bottom: -1px;
  border-radius: 3px;
  cursor: pointer;
  background-color: #85bbb6;
  opacity: 0.7;
}
.main-menu-new-wrap .custom-scroll_bar-x:hover,
.main-menu-new-wrap .custom-scroll_bar-x:active {
  opacity: 1;
}
.main-menu-new-header {
  padding: 6px 0 17px 0;
}
.main-menu-new-header__on-map {
  font-size: 1.5rem;
  color: #666;
  font-weight: 300;
  display: block;
  text-transform: uppercase;
  text-align: center;
}
.main-menu-new-info {
  padding: 6px 0 17px 0;
}
.main-menu-new-info-cont {
  font-size: 0;
}
.main-menu-new-info__title {
  font-size: 1.5rem;
  color: #666;
  font-weight: 300;
  display: inline-block;
  vertical-align: middle;
  text-transform: uppercase;
  width: 155px;
}
.main-menu-new-info__desc {
  font-size: 0.75rem;
  display: inline-block;
  vertical-align: middle;
  color: #999;
  line-height: 1.2;
  width: calc(100% - 155px);
}
.main-menu-new-info__on-map {
  font-size: 1.5rem;
  color: #666;
  font-weight: 300;
  display: block;
  text-transform: uppercase;
  text-align: center;
}
.main-menu-new-action {
  width: 210px;
  height: 210px;
  border-radius: 5px;
  background-color: #f0f0f0;
  color: #fff;
  position: absolute;
  z-index: 2;
  left: 50%;
  margin-left: -105px;
  top: 68px;
  box-shadow: 0 6px 14px 4px rgba(0,0,0,0.3);
  padding: 6px;
  text-decoration: none;
}
.main-menu-new-action:hover {
  background-color: #fff;
}
.main-menu-new-action:hover::after {
  border-top-color: #fff;
}
.main-menu-new-action:hover .main-menu-new-action-cont {
  background-color: #2b8b82;
}
.main-menu-new-action:hover::before {
  border-top-color: #2b8b82;
}
.main-menu-new-action::after {
  content: "";
  display: block;
  position: absolute;
  bottom: -39px;
  left: 0;
  border: 105px solid transparent;
  border-top-color: #f0f0f0;
  border-top-width: 20px;
  border-bottom-width: 20px;
  border-radius: 5px;
}
.main-menu-new-action::before {
  content: "";
  display: block;
  position: absolute;
  bottom: -33px;
  left: 6px;
  border: 100px solid transparent;
  border-top-color: #29837b;
  border-top-width: 20px;
  border-bottom-width: 20px;
  border-radius: 6px;
  z-index: 1;
}
.main-menu-new-action-cont {
  padding-top: 20px;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background-color: #29837b;
  position: relative;
}
.main-menu-new-action__icn {
  display: block;
  width: 120px;
  height: 125px;
  stroke: #fff;
  stroke-width: 1;
  fill: #cacaca;
  margin: 0 auto 10px;
}
.main-menu-new-action__title {
  text-align: center;
  text-transform: uppercase;
  line-height: 1;
  font-weight: bold;
}
.main-menu-new-action-help {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 15px;
  height: 15px;
  padding: 3px;
  border-radius: 50%;
}
.main-menu-new-action-help .icn-help-cont {
  top: 15px;
  right: 15px;
}
.main-menu-new-header-more {
  text-decoration: none;
  color: #666;
  display: inline-block;
  vertical-align: middle;
  font-size: 0;
}
.main-menu-new-header-more:hover .main-menu-new-header-more__txt {
  text-decoration: underline;
}
.main-menu-new-header-more__txt {
  display: inline;
  vertical-align: middle;
  font-size: 0.875rem;
}
.main-menu-new-header-more__icn {
  width: 10px;
  transform: rotate(-90deg);
  height: 10px;
  fill: currentColor;
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
}
.main-menu-new-header__add-place {
  padding: 0 30px;
  line-height: 35px;
  display: inline-block;
  border-radius: 2px;
  float: right;
  text-decoration: none;
}
.main-menu-new-gallery {
  height: 180px;
  white-space: nowrap;
  margin-bottom: 50px;
}
.main-menu-new-gallery-item {
  width: 180px;
  height: 180px;
  overflow: hidden;
  display: inline-block;
}
.main-menu-new-scroll {
  padding: 0 30px;
}
.main-menu-new-scroll__path {
  height: 3px;
  background-color: #d9d9d9;
  border-radius: 2px;
}
.main-menu-search-header {
  padding: 22px 0 5px 0;
  font-size: 1.875rem;
  text-align: center;
  font-weight: 300;
  color: #666;
  text-transform: uppercase;
}
.main-menu-search-wrap {
  margin-bottom: 40px;
}
.main-menu-user {
  display: table;
  margin: 30px 0 25px;
}
.main-menu-user:after {
  display: block;
  clear: both;
  content: '';
}
.main-menu-user__header {
  width: 25%;
  display: table-cell;
  vertical-align: top;
  position: relative;
  padding-top: 6px;
  font-weight: 300;
  color: #666;
}
.main-menu-user__header:after {
  content: '';
  display: inline-block;
  width: 1px;
  height: 80px;
  background-color: #666;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
}
.main-menu-user__text {
  display: table-cell;
  vertical-align: top;
  width: 75%;
  padding: 0 5px 0 20px;
  color: #666;
  font-size: 0.875rem;
  line-height: 1.2;
  font-weight: 300;
}
.main-menu-gallery {
  margin-bottom: 15px;
}
.main-menu-gallery [class^="main-menu-gallery__plate"] {
  margin-bottom: 12px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  display: block;
  border-radius: 3px;
  overflow: hidden;
}
.main-menu-gallery__plate_small {
  height: 180px;
}
.main-menu-gallery__plate_small .main-menu-gallery__info {
  max-height: 170px;
}
.main-menu-gallery__img {
  display: block;
  width: 100%;
  height: 100%;
}
.main-menu-gallery__plate_big {
  height: 372px;
}
.main-menu-gallery__plate_big .main-menu-gallery__info {
  max-height: 362px;
}
.main-menu-gallery__shadow {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.3);
}
.main-menu-gallery__info {
  position: absolute;
  bottom: 0;
  padding: 10px 10px 0 10px;
  margin-bottom: 10px;
  color: #fff;
  max-height: inherit;
  overflow: hidden;
}
.main-menu-gallery__name {
  margin-bottom: 10px;
  font-size: 0.875rem;
}
.main-menu-gallery__desc {
  display: none;
  opacity: 0;
  font-size: 0.75rem;
}
.main-menu-gallery [class^="main-menu-gallery__plate"]:hover .main-menu-gallery__shadow {
  background-color: rgba(0,0,0,0.7);
}
.main-menu-gallery [class^="main-menu-gallery__plate"]:hover .main-menu-gallery__desc {
  display: block;
  opacity: 1;
}
.main-menu-gallery [class^="main-menu-gallery__plate"]:hover .main-menu-gallery__name {
  font-size: 1.125rem;
}
.main-menu-map {
  background: #ffcb80;
  padding-top: 340px;
  box-shadow: inset 0px 50px 50px -50px rgba(0,0,0,0.33), inset 0px -50px 50px -50px rgba(0,0,0,0.33);
}
.main-menu-map__table {
  display: table;
}
.main-menu-map__right,
.main-menu-map__left {
  width: 50%;
  display: table-cell;
  vertical-align: middle;
}
.main-menu-map__left {
  background-color: #fff;
  padding: 29px 43px;
}
.main-menu-map__right {
  background-color: rgba(255,255,255,0.8);
  text-align: center;
}
.main-menu-map__icon {
  width: 200px;
  height: 185px;
  display: inline-block;
}
.main-menu-map__header {
  font-size: 2rem;
  color: #666;
  font-weight: 300;
  margin-bottom: 11px;
  letter-spacing: 0.6px;
}
.main-menu-map__text {
  color: #666;
  margin-bottom: 34px;
  line-height: 1.2;
}
.main-menu-map-agree {
  height: 45px;
  line-height: 43px;
  width: 100%;
  display: block;
  border-radius: 3px;
  font-size: 0;
}
.main-menu-map-agree__txt {
  font-size: 0.875rem;
  display: inline;
  vertical-align: middle;
  font-weight: normal;
}
.main-menu-map-agree__icn {
  display: inline-block;
  vertical-align: middle;
  width: 22px;
  height: 22px;
  fill: #fff;
  filter: drop-shadow(0 1px 1px rgba(0,0,0,0.3));
  margin-left: 10px;
}
.main-menu-top {
  border-top: 15px solid #3b8e86;
  min-height: 10px;
  background: url("../img/bg/profile-bg-locked.png") repeat center #4fa39c;
}
.main-menu-top_bb {
  border-bottom: 35px solid #3b8e86;
}
.main-menu-top-head {
  margin: 30px 0;
}
.main-menu-top-head:after {
  display: block;
  clear: both;
  content: '';
}
.main-menu-top-head__title {
  float: left;
  font-size: 2.125rem;
  font-weight: 300;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: -0.5px;
  line-height: 1;
}
.main-menu-top-head__btn {
  float: right;
  width: 240px;
  height: 35px;
  line-height: 33px;
  border-radius: 3px;
  display: block;
  font-size: 0.875rem;
  font-weight: bold;
}
.main-menu-top-lst {
  padding-bottom: 20px;
  display: block;
  text-align: center;
}
.main-menu-top-lst:after {
  width: 83.33333333333333%;
  height: 4px;
  background-color: rgba(255,255,255,0.2);
  display: block;
  content: '';
  margin: 0 auto;
  position: relative;
  top: 22px;
}
.main-menu-top-cell {
  width: 300px;
  vertical-align: top;
  padding: 0 15px;
  margin-bottom: 15px;
  display: inline-block;
}
.main-menu-top-type {
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 25px;
}
.main-menu-top-itm {
  text-align: left;
}
.people-both-wrap {
  padding-bottom: 65px;
}
.people-both {
  padding: 45px 80px;
  position: relative;
  background-color: #fff;
}
.people-both__title {
  text-align: center;
  color: #666;
  font-size: 2.0625rem;
  line-height: 1;
  font-weight: 300;
  text-transform: uppercase;
}
.people-both__desc {
  font-size: 0.875rem;
  text-align: center;
  line-height: 1.2;
  color: #666;
  margin: 25px 0;
}
.people-both::after,
.people-both::before {
  position: absolute;
  content: "";
  width: 50%;
  bottom: 20px;
  box-shadow: 0 0 60px 10px rgba(0,0,0,0.5);
  z-index: -1;
}
.people-both::before {
  left: 10px;
  transform: rotate(-5deg);
}
.people-both::after {
  right: 10px;
  transform: rotate(5deg);
}
.people-both-slider .slick-dots {
  position: absolute;
  bottom: -50px;
  left: 50%;
  margin-left: -30px;
}
.people-both-slider .slick-dots li {
  display: inline-block;
  vertical-align: middle;
  margin: 0 3px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #cbcbcb;
  padding: 2px;
  color: transparent;
}
.people-both-slider .slick-dots .slick-active button {
  background: linear-gradient(to bottom, #96cbc8 0%, #28706d 100%);
}
.people-both-slider .slick-dots button {
  background-color: transparent;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.people-both-slider .slick-arrow {
  position: absolute;
  top: 50%;
  z-index: 1;
  background-size: cover;
  width: 25px;
  height: 35px;
  background: url("/arrow-left-green.svg#base64") center no-repeat transparent;
  opacity: 0.6;
  color: transparent;
}
.people-both-slider .slick-arrow:hover {
  opacity: 1;
}
.people-both-slider .slick-prev {
  left: 25px;
}
.people-both-slider .slick-next {
  right: 25px;
  transform: rotate(180deg);
}
.people-both-stats {
  position: absolute;
  top: 200px;
  left: 50%;
  transform: translateX(-50%);
}
.people-both-stats-hearts {
  margin: 0 auto 60px;
}
.people-both-stats-hearts__img {
  max-width: 200px;
  margin: 0 auto;
  display: block;
}
.people-both-stats-count {
  color: #a7cdca;
  font-size: 1.875rem;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
}
.people-both-luser {
  font-size: 0;
}
.people-both-luser-ava {
  display: inline-block;
  vertical-align: middle;
  width: 180px;
  height: 180px;
}
.people-both-luser-ava__img {
  width: 100%;
  height: 100%;
  border: 3px solid #c2ecea;
  border-radius: 3px;
}
.people-both-luser-query {
  width: calc(100% - 180px);
  display: inline-block;
  vertical-align: middle;
  font-size: 0.875rem;
  line-height: 1.2;
  padding-left: 15px;
}
.people-both-luser-query__title {
  font-weight: bold;
  color: #666;
}
.people-both-luser-query__text {
  color: #999;
}
.people-both-ruser {
  font-size: 0;
}
.people-both-ruser-ava {
  display: inline-block;
  vertical-align: middle;
  width: 180px;
  height: 180px;
}
.people-both-ruser-ava__img {
  display: block;
  width: 100%;
  height: 100%;
  border: 3px solid #c2ecea;
  border-radius: 3px;
}
.people-both-ruser-query {
  display: inline-block;
  vertical-align: middle;
  font-size: 0.875rem;
  line-height: 1.2;
  width: calc(100% - 180px);
  padding-right: 15px;
}
.people-both-ruser-query__title {
  font-weight: bold;
  color: #666;
}
.people-both-ruser-query__text {
  color: #999;
}
.people-both-linfo,
.people-both-rinfo {
  width: 180px;
}
.people-both-rinfo {
  float: right;
}
.people-both-story-top {
  display: table;
  width: 100%;
  margin-bottom: 10px;
}
.people-both-story-lft,
.people-both-story-rgt,
.people-both-story-mdl {
  display: table-cell;
  vertical-align: middle;
  width: 33%;
}
.people-both-story-rgt {
  text-align: right;
}
.people-both-story-info {
  display: table;
  width: 100%;
}
.people-both-story-info-name {
  color: #343434;
  font-size: 1.125rem;
  font-weight: 300;
  width: 180px;
}
.people-both-story-info-name__lnk {
  text-decoration: none;
  color: inherit;
}
.people-both-story-info-name__lnk:hover {
  text-decoration: underline;
}
.people-both-story-info__about {
  font-size: 0.875rem;
  font-weight: bold;
  width: 180px;
}
.people-both-story-info-lft {
  display: table-cell;
  width: 50%;
  vertical-align: bottom;
}
.people-both-story-info-rgt {
  display: table-cell;
  width: 50%;
  vertical-align: bottom;
  text-align: right;
}
.people-words-wrap {
  background: url("../img/bg/profile-bg-locked.png") repeat center #3b8e86;
  padding-bottom: 25px;
}
.people-words {
  text-align: center;
}
.people-words__title {
  padding: 17px 0 25px;
  color: #fff;
  font-size: 1.5rem;
  font-weight: bold;
  text-transform: uppercase;
}
.people-words-lst {
  margin: 0 -5px 15px;
  height: 80px;
  overflow: hidden;
}
.people-words_opened .people-words-lst {
  height: auto;
}
.people-words-cell {
  width: 180px;
  padding: 0 5px;
  margin-bottom: 10px;
  display: inline-block;
  vertical-align: top;
}
.people-words-itm {
  background-color: rgba(0,0,0,0.05);
  border-radius: 3px;
  color: #fff;
  font-size: 0.9375rem;
  height: 30px;
  line-height: 30px;
  box-shadow: inset 0px 1px 1px -1px rgba(0,0,0,0.27), 0px 1px 1px -1px rgba(255,255,255,0.51), inset 0px 0px 5px 0px rgba(0,0,0,0.1);
}
.people-words-all {
  color: #fff;
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
}
.people-words-all__txt {
  vertical-align: middle;
  text-decoration: underline;
}
.people-words-all:hover .people-words-all__txt {
  text-decoration: none;
}
.people-words-all__icn {
  width: 10px;
  height: 10px;
  display: inline-block;
  vertical-align: middle;
  fill: currentColor;
  position: relative;
  top: 1px;
  left: 2px;
}
.people-words_opened .people-words-all__icn {
  transform: rotate(180deg);
}
.people-services-wrap {
  box-shadow: inset 0px 15px 25px -15px rgba(0,0,0,0.5);
}
.people-services {
  padding: 45px 0 55px;
}
.people-services__title {
  color: #666;
  text-align: center;
  font-size: 2.0625rem;
  line-height: 1;
  font-weight: 300;
  margin-bottom: 30px;
}
.people-services__title span {
  text-transform: uppercase;
}
.people-now-wrap {
  background-color: #fafafa;
}
.people-now {
  padding: 30px 0;
}
.people-now__title {
  line-height: 1;
  font-size: 2.0625rem;
  font-weight: 300;
  color: #666;
  margin-bottom: 50px;
}
.people-now-layout {
  display: table;
  width: 100%;
}
.people-now-layout-lft,
.people-now-layout-rgt {
  display: table-cell;
  vertical-align: top;
}
.people-now-layout-rgt {
  width: 420px;
  padding-left: 45px;
}
.people-now-news {
  padding-bottom: 25px;
  margin-bottom: 25px;
  font-size: 0;
  border-bottom: 1px dotted #c1c1c1;
}
.people-now-news:last-child {
  border-bottom: none;
}
.people-now-news-lft,
.people-now-news-rgt {
  display: inline-block;
  vertical-align: top;
  font-size: 1rem;
}
.people-now-news-lft {
  width: 95px;
}
.people-now-news-rgt {
  width: calc(100% - 95px);
  padding-left: 15px;
}
.people-now-ava {
  display: block;
  height: 95px;
}
.people-now-ava__img {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 3px;
}
.people-now-username {
  line-height: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.people-now-username__lnk {
  color: #343434;
  font-size: 1rem;
  text-decoration: none;
  font-weight: bold;
}
.people-now-username__lnk:hover {
  text-decoration: underline;
}
.people-now-action {
  font-size: 0.875rem;
  color: #999;
  line-height: 1;
  margin: 5px 0 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.people-now-mode {
  font-size: 0.75rem;
  display: block;
  font-weight: normal;
  border-radius: 3px;
  height: 35px;
  width: 218px;
  line-height: 33px;
}
.publications-widgets {
  padding: 55px 0;
}
.mm-gal-people {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  color: inherit;
  text-decoration: none;
}
.mm-gal-people__img {
  display: block;
  width: 100%;
  height: 100%;
}
.mm-gal-people_locked .mm-gal-people__img {
  filter: blur(10px);
}
.mm-gal-people-over {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("../img/bg/profile-bg-locked.png") repeat center transparent;
}
.mm-gal-people-lock {
  width: 45px;
  height: 65px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -22.5px;
  margin-top: -32.5px;
  white-space: normal;
  z-index: 1;
}
.mm-gal-people-lock__icn {
  display: block;
  width: 100%;
  height: 100%;
  fill: rgba(255,255,255,0.7);
  stroke: rgba(0,0,0,0.1);
  stroke-width: 3;
  filter: drop-shadow(0 1px 1px rgba(0,0,0,0.3));
}
.mm-gal-people-tip {
  position: absolute;
  bottom: -10px;
  right: -10px;
  width: 15px;
  height: 15px;
  padding: 3px;
  border-radius: 50%;
}
.mm-gal-people-tip .icn-help-cont {
  bottom: 13px;
  right: 13px;
}
.mm-gal-people-state__status {
  display: inline-block;
  height: 14px;
  padding: 0 10px;
  text-transform: lowercase;
  color: #fff;
  border-radius: 3px;
  font-size: 0.6875rem;
  font-weight: 300;
}
.mm-gal-people-state__status_on {
  background-color: #95cd7d;
}
.mm-gal-people-state__status_off {
  background-color: #cfcfcf;
}
.mm-gal-people-info {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 90px;
  padding: 38px 15px 0;
  background: linear-gradient(to bottom, transparent 0%, #000 100%);
  opacity: 0.75;
  transition: opacity 0.3s 0s;
  white-space: normal;
}
.mm-gal-people_locked .mm-gal-people-info {
  opacity: 1;
}
.mm-gal-people:hover .mm-gal-people-info {
  opacity: 1;
}
.mm-gal-people-who {
  margin-top: 2px;
  position: relative;
  display: inline-block;
  color: #e9e9e9;
  font-size: 0.9375rem;
}
.mm-gal-people-who__name {
  display: inline-block;
  vertical-align: middle;
  max-width: 95px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.mm-gal-people-who__name::after {
  content: ", ";
  margin-right: 3px;
}
.mm-gal-people-who__age {
  display: inline-block;
  vertical-align: middle;
}
.mm-gal-people-who__flirt,
.mm-gal-people-who__serious {
  width: 20px;
  height: 15px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: -7px;
  fill: #fff;
}
.mm-search {
  background: #308079;
  background: linear-gradient(to bottom, #308079 0%, #53a7a0 100%);
  box-shadow: inset 0px 0px 3px 0px rgba(0,0,0,0.49);
  border-radius: 3px;
  padding: 13px 9px 15px;
}
.mm-search-head {
  padding: 0 2px 0 5px;
  margin-bottom: 10px;
}
.mm-search-head:after {
  display: block;
  clear: both;
  content: '';
}
.mm-search-head-lft {
  padding-top: 6px;
  float: left;
}
.mm-search-head-rgt {
  float: right;
}
.mm-search-head__title {
  font-size: 1.5rem;
  line-height: 1;
  font-weight: 300;
  color: #fff;
}
.mm-search-head-type {
  display: block;
  list-style: none;
  font-size: 0;
}
.mm-search-head-type-itm {
  display: inline-block;
  vertical-align: middle;
  color: #9bbfbc;
  margin-left: 5px;
  border-radius: 4px;
}
.mm-search-head-type-itm_active,
.mm-search-head-type-itm:hover {
  color: #fff;
}
.mm-search-head-type-itm_active {
  background-color: #2d756f;
}
.mm-search-head-type-itm__lnk {
  display: block;
  padding: 12px;
  line-height: 1;
  text-transform: uppercase;
  color: inherit;
  text-decoration: none;
  font-weight: bold;
  font-size: 0.875rem;
}
.mm-search-filter:after {
  display: block;
  clear: both;
  content: '';
}
.mm-search-filter-lft {
  float: left;
  width: calc(100% - 140px);
}
.mm-search-filter-rgt {
  float: right;
  width: 120px;
}
.mm-search-filter-btn {
  width: 100%;
  height: 44px;
  display: block;
  border-radius: 3px;
  text-align: center;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: bold;
  line-height: 42px;
}
.mm-search-filter-opts {
  width: 100%;
  display: table;
  position: relative;
}
.mm-search-filter-m-sex,
.mm-search-filter-m-place,
.mm-search-filter-m-city,
.mm-search-filter-m-date,
.mm-search-filter-m-more,
.mm-search-filter-pl-sex,
.mm-search-filter-pl-age,
.mm-search-filter-pl-city,
.mm-search-filter-pl-goal,
.mm-search-filter-pl-more,
.mm-search-filter-vc-sex,
.mm-search-filter-vc-age,
.mm-search-filter-vc-city,
.mm-search-filter-vc-goal,
.mm-search-filter-vc-more,
.mm-search-filter-p-type,
.mm-search-filter-p-city,
.mm-search-filter-p-place,
.mm-search-filter-p-price-from,
.mm-search-filter-p-price-to,
.mm-search-filter-p-more,
.mm-search-filter-pub-type,
.mm-search-filter-pub-city,
.mm-search-filter-pub-place,
.mm-search-filter-pub-price-from,
.mm-search-filter-pub-price-to,
.mm-search-filter-pub-more {
  display: table-cell;
  vertical-align: top;
}
.mm-search-filter-m-sex__select,
.mm-search-filter-m-place__select,
.mm-search-filter-m-city__select,
.mm-search-filter-m-date__select,
.mm-search-filter-m-more__select,
.mm-search-filter-pl-sex__select,
.mm-search-filter-pl-age__select,
.mm-search-filter-pl-city__select,
.mm-search-filter-pl-goal__select,
.mm-search-filter-pl-more__select,
.mm-search-filter-vc-sex__select,
.mm-search-filter-vc-age__select,
.mm-search-filter-vc-city__select,
.mm-search-filter-vc-goal__select,
.mm-search-filter-vc-more__select,
.mm-search-filter-p-type__select,
.mm-search-filter-p-city__select,
.mm-search-filter-p-place__select,
.mm-search-filter-p-price-from__select,
.mm-search-filter-p-price-to__select,
.mm-search-filter-p-more__select,
.mm-search-filter-pub-type__select,
.mm-search-filter-pub-city__select,
.mm-search-filter-pub-place__select,
.mm-search-filter-pub-price-from__select,
.mm-search-filter-pub-price-to__select,
.mm-search-filter-pub-more__select {
  width: 100%;
  height: 44px;
  appearance: none;
  border: 1px solid #959595;
  border-right: none;
  font-size: 0.75rem;
  color: #666;
  padding: 0 20px;
  background: url("/arrow-down-gray.svg#base64") no-repeat 95% 50% #fff;
}
.mm-search-filter-m-sex__select:hover,
.mm-search-filter-m-place__select:hover,
.mm-search-filter-m-city__select:hover,
.mm-search-filter-m-date__select:hover,
.mm-search-filter-m-more__select:hover,
.mm-search-filter-pl-sex__select:hover,
.mm-search-filter-pl-age__select:hover,
.mm-search-filter-pl-city__select:hover,
.mm-search-filter-pl-goal__select:hover,
.mm-search-filter-pl-more__select:hover,
.mm-search-filter-vc-sex__select:hover,
.mm-search-filter-vc-age__select:hover,
.mm-search-filter-vc-city__select:hover,
.mm-search-filter-vc-goal__select:hover,
.mm-search-filter-vc-more__select:hover,
.mm-search-filter-p-type__select:hover,
.mm-search-filter-p-city__select:hover,
.mm-search-filter-p-place__select:hover,
.mm-search-filter-p-price-from__select:hover,
.mm-search-filter-p-price-to__select:hover,
.mm-search-filter-p-more__select:hover,
.mm-search-filter-pub-type__select:hover,
.mm-search-filter-pub-city__select:hover,
.mm-search-filter-pub-place__select:hover,
.mm-search-filter-pub-price-from__select:hover,
.mm-search-filter-pub-price-to__select:hover,
.mm-search-filter-pub-more__select:hover {
  background-color: #f6f6f6;
}
.mm-search-filter-m-sex .chosen-container,
.mm-search-filter-m-place .chosen-container,
.mm-search-filter-m-city .chosen-container,
.mm-search-filter-m-date .chosen-container,
.mm-search-filter-m-more .chosen-container,
.mm-search-filter-pl-sex .chosen-container,
.mm-search-filter-pl-age .chosen-container,
.mm-search-filter-pl-city .chosen-container,
.mm-search-filter-pl-goal .chosen-container,
.mm-search-filter-pl-more .chosen-container,
.mm-search-filter-vc-sex .chosen-container,
.mm-search-filter-vc-age .chosen-container,
.mm-search-filter-vc-city .chosen-container,
.mm-search-filter-vc-goal .chosen-container,
.mm-search-filter-vc-more .chosen-container,
.mm-search-filter-p-type .chosen-container,
.mm-search-filter-p-city .chosen-container,
.mm-search-filter-p-place .chosen-container,
.mm-search-filter-p-price-from .chosen-container,
.mm-search-filter-p-price-to .chosen-container,
.mm-search-filter-p-more .chosen-container,
.mm-search-filter-pub-type .chosen-container,
.mm-search-filter-pub-city .chosen-container,
.mm-search-filter-pub-place .chosen-container,
.mm-search-filter-pub-price-from .chosen-container,
.mm-search-filter-pub-price-to .chosen-container,
.mm-search-filter-pub-more .chosen-container {
  background-color: #fff;
  border: 1px solid #959595;
  border-right: none;
  position: relative;
  height: 44px;
  font-size: 0.875rem;
  color: #666;
  width: 100% !important;
}
.mm-search-filter-m-sex .chosen-container:hover .chosen-single,
.mm-search-filter-m-place .chosen-container:hover .chosen-single,
.mm-search-filter-m-city .chosen-container:hover .chosen-single,
.mm-search-filter-m-date .chosen-container:hover .chosen-single,
.mm-search-filter-m-more .chosen-container:hover .chosen-single,
.mm-search-filter-pl-sex .chosen-container:hover .chosen-single,
.mm-search-filter-pl-age .chosen-container:hover .chosen-single,
.mm-search-filter-pl-city .chosen-container:hover .chosen-single,
.mm-search-filter-pl-goal .chosen-container:hover .chosen-single,
.mm-search-filter-pl-more .chosen-container:hover .chosen-single,
.mm-search-filter-vc-sex .chosen-container:hover .chosen-single,
.mm-search-filter-vc-age .chosen-container:hover .chosen-single,
.mm-search-filter-vc-city .chosen-container:hover .chosen-single,
.mm-search-filter-vc-goal .chosen-container:hover .chosen-single,
.mm-search-filter-vc-more .chosen-container:hover .chosen-single,
.mm-search-filter-p-type .chosen-container:hover .chosen-single,
.mm-search-filter-p-city .chosen-container:hover .chosen-single,
.mm-search-filter-p-place .chosen-container:hover .chosen-single,
.mm-search-filter-p-price-from .chosen-container:hover .chosen-single,
.mm-search-filter-p-price-to .chosen-container:hover .chosen-single,
.mm-search-filter-p-more .chosen-container:hover .chosen-single,
.mm-search-filter-pub-type .chosen-container:hover .chosen-single,
.mm-search-filter-pub-city .chosen-container:hover .chosen-single,
.mm-search-filter-pub-place .chosen-container:hover .chosen-single,
.mm-search-filter-pub-price-from .chosen-container:hover .chosen-single,
.mm-search-filter-pub-price-to .chosen-container:hover .chosen-single,
.mm-search-filter-pub-more .chosen-container:hover .chosen-single {
  background-color: #f6f6f6;
}
.mm-search-filter-m-sex .chosen-drop,
.mm-search-filter-m-place .chosen-drop,
.mm-search-filter-m-city .chosen-drop,
.mm-search-filter-m-date .chosen-drop,
.mm-search-filter-m-more .chosen-drop,
.mm-search-filter-pl-sex .chosen-drop,
.mm-search-filter-pl-age .chosen-drop,
.mm-search-filter-pl-city .chosen-drop,
.mm-search-filter-pl-goal .chosen-drop,
.mm-search-filter-pl-more .chosen-drop,
.mm-search-filter-vc-sex .chosen-drop,
.mm-search-filter-vc-age .chosen-drop,
.mm-search-filter-vc-city .chosen-drop,
.mm-search-filter-vc-goal .chosen-drop,
.mm-search-filter-vc-more .chosen-drop,
.mm-search-filter-p-type .chosen-drop,
.mm-search-filter-p-city .chosen-drop,
.mm-search-filter-p-place .chosen-drop,
.mm-search-filter-p-price-from .chosen-drop,
.mm-search-filter-p-price-to .chosen-drop,
.mm-search-filter-p-more .chosen-drop,
.mm-search-filter-pub-type .chosen-drop,
.mm-search-filter-pub-city .chosen-drop,
.mm-search-filter-pub-place .chosen-drop,
.mm-search-filter-pub-price-from .chosen-drop,
.mm-search-filter-pub-price-to .chosen-drop,
.mm-search-filter-pub-more .chosen-drop {
  background-color: #fff;
  border: 1px solid #959595;
  border-radius: 3px;
}
.mm-search-filter-m-sex .chosen-single,
.mm-search-filter-m-place .chosen-single,
.mm-search-filter-m-city .chosen-single,
.mm-search-filter-m-date .chosen-single,
.mm-search-filter-m-more .chosen-single,
.mm-search-filter-pl-sex .chosen-single,
.mm-search-filter-pl-age .chosen-single,
.mm-search-filter-pl-city .chosen-single,
.mm-search-filter-pl-goal .chosen-single,
.mm-search-filter-pl-more .chosen-single,
.mm-search-filter-vc-sex .chosen-single,
.mm-search-filter-vc-age .chosen-single,
.mm-search-filter-vc-city .chosen-single,
.mm-search-filter-vc-goal .chosen-single,
.mm-search-filter-vc-more .chosen-single,
.mm-search-filter-p-type .chosen-single,
.mm-search-filter-p-city .chosen-single,
.mm-search-filter-p-place .chosen-single,
.mm-search-filter-p-price-from .chosen-single,
.mm-search-filter-p-price-to .chosen-single,
.mm-search-filter-p-more .chosen-single,
.mm-search-filter-pub-type .chosen-single,
.mm-search-filter-pub-city .chosen-single,
.mm-search-filter-pub-place .chosen-single,
.mm-search-filter-pub-price-from .chosen-single,
.mm-search-filter-pub-price-to .chosen-single,
.mm-search-filter-pub-more .chosen-single {
  display: block;
  height: 100%;
  line-height: 42px;
  padding-left: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.mm-search-filter-m-sex div b,
.mm-search-filter-m-place div b,
.mm-search-filter-m-city div b,
.mm-search-filter-m-date div b,
.mm-search-filter-m-more div b,
.mm-search-filter-pl-sex div b,
.mm-search-filter-pl-age div b,
.mm-search-filter-pl-city div b,
.mm-search-filter-pl-goal div b,
.mm-search-filter-pl-more div b,
.mm-search-filter-vc-sex div b,
.mm-search-filter-vc-age div b,
.mm-search-filter-vc-city div b,
.mm-search-filter-vc-goal div b,
.mm-search-filter-vc-more div b,
.mm-search-filter-p-type div b,
.mm-search-filter-p-city div b,
.mm-search-filter-p-place div b,
.mm-search-filter-p-price-from div b,
.mm-search-filter-p-price-to div b,
.mm-search-filter-p-more div b,
.mm-search-filter-pub-type div b,
.mm-search-filter-pub-city div b,
.mm-search-filter-pub-place div b,
.mm-search-filter-pub-price-from div b,
.mm-search-filter-pub-price-to div b,
.mm-search-filter-pub-more div b {
  width: 20px;
  background-image: url("/arrow-down-gray.svg#base64");
  background-repeat: no-repeat;
  background-position: center left;
}
.mm-search-filter-m-sex .chosen-with-drop div b,
.mm-search-filter-m-place .chosen-with-drop div b,
.mm-search-filter-m-city .chosen-with-drop div b,
.mm-search-filter-m-date .chosen-with-drop div b,
.mm-search-filter-m-more .chosen-with-drop div b,
.mm-search-filter-pl-sex .chosen-with-drop div b,
.mm-search-filter-pl-age .chosen-with-drop div b,
.mm-search-filter-pl-city .chosen-with-drop div b,
.mm-search-filter-pl-goal .chosen-with-drop div b,
.mm-search-filter-pl-more .chosen-with-drop div b,
.mm-search-filter-vc-sex .chosen-with-drop div b,
.mm-search-filter-vc-age .chosen-with-drop div b,
.mm-search-filter-vc-city .chosen-with-drop div b,
.mm-search-filter-vc-goal .chosen-with-drop div b,
.mm-search-filter-vc-more .chosen-with-drop div b,
.mm-search-filter-p-type .chosen-with-drop div b,
.mm-search-filter-p-city .chosen-with-drop div b,
.mm-search-filter-p-place .chosen-with-drop div b,
.mm-search-filter-p-price-from .chosen-with-drop div b,
.mm-search-filter-p-price-to .chosen-with-drop div b,
.mm-search-filter-p-more .chosen-with-drop div b,
.mm-search-filter-pub-type .chosen-with-drop div b,
.mm-search-filter-pub-city .chosen-with-drop div b,
.mm-search-filter-pub-place .chosen-with-drop div b,
.mm-search-filter-pub-price-from .chosen-with-drop div b,
.mm-search-filter-pub-price-to .chosen-with-drop div b,
.mm-search-filter-pub-more .chosen-with-drop div b {
  background-image: url("/arrow-left-gray.svg#base64");
}
.mm-search-filter-m-sex .chosen-search,
.mm-search-filter-m-place .chosen-search,
.mm-search-filter-m-city .chosen-search,
.mm-search-filter-m-date .chosen-search,
.mm-search-filter-m-more .chosen-search,
.mm-search-filter-pl-sex .chosen-search,
.mm-search-filter-pl-age .chosen-search,
.mm-search-filter-pl-city .chosen-search,
.mm-search-filter-pl-goal .chosen-search,
.mm-search-filter-pl-more .chosen-search,
.mm-search-filter-vc-sex .chosen-search,
.mm-search-filter-vc-age .chosen-search,
.mm-search-filter-vc-city .chosen-search,
.mm-search-filter-vc-goal .chosen-search,
.mm-search-filter-vc-more .chosen-search,
.mm-search-filter-p-type .chosen-search,
.mm-search-filter-p-city .chosen-search,
.mm-search-filter-p-place .chosen-search,
.mm-search-filter-p-price-from .chosen-search,
.mm-search-filter-p-price-to .chosen-search,
.mm-search-filter-p-more .chosen-search,
.mm-search-filter-pub-type .chosen-search,
.mm-search-filter-pub-city .chosen-search,
.mm-search-filter-pub-place .chosen-search,
.mm-search-filter-pub-price-from .chosen-search,
.mm-search-filter-pub-price-to .chosen-search,
.mm-search-filter-pub-more .chosen-search {
  border-bottom: 1px solid #959595;
}
.mm-search-filter-m-sex .chosen-results li,
.mm-search-filter-m-place .chosen-results li,
.mm-search-filter-m-city .chosen-results li,
.mm-search-filter-m-date .chosen-results li,
.mm-search-filter-m-more .chosen-results li,
.mm-search-filter-pl-sex .chosen-results li,
.mm-search-filter-pl-age .chosen-results li,
.mm-search-filter-pl-city .chosen-results li,
.mm-search-filter-pl-goal .chosen-results li,
.mm-search-filter-pl-more .chosen-results li,
.mm-search-filter-vc-sex .chosen-results li,
.mm-search-filter-vc-age .chosen-results li,
.mm-search-filter-vc-city .chosen-results li,
.mm-search-filter-vc-goal .chosen-results li,
.mm-search-filter-vc-more .chosen-results li,
.mm-search-filter-p-type .chosen-results li,
.mm-search-filter-p-city .chosen-results li,
.mm-search-filter-p-place .chosen-results li,
.mm-search-filter-p-price-from .chosen-results li,
.mm-search-filter-p-price-to .chosen-results li,
.mm-search-filter-p-more .chosen-results li,
.mm-search-filter-pub-type .chosen-results li,
.mm-search-filter-pub-city .chosen-results li,
.mm-search-filter-pub-place .chosen-results li,
.mm-search-filter-pub-price-from .chosen-results li,
.mm-search-filter-pub-price-to .chosen-results li,
.mm-search-filter-pub-more .chosen-results li {
  border-bottom: inherit;
  padding: 5px 20px;
}
.mm-search-filter-m-sex .chosen-results li:last-child,
.mm-search-filter-m-place .chosen-results li:last-child,
.mm-search-filter-m-city .chosen-results li:last-child,
.mm-search-filter-m-date .chosen-results li:last-child,
.mm-search-filter-m-more .chosen-results li:last-child,
.mm-search-filter-pl-sex .chosen-results li:last-child,
.mm-search-filter-pl-age .chosen-results li:last-child,
.mm-search-filter-pl-city .chosen-results li:last-child,
.mm-search-filter-pl-goal .chosen-results li:last-child,
.mm-search-filter-pl-more .chosen-results li:last-child,
.mm-search-filter-vc-sex .chosen-results li:last-child,
.mm-search-filter-vc-age .chosen-results li:last-child,
.mm-search-filter-vc-city .chosen-results li:last-child,
.mm-search-filter-vc-goal .chosen-results li:last-child,
.mm-search-filter-vc-more .chosen-results li:last-child,
.mm-search-filter-p-type .chosen-results li:last-child,
.mm-search-filter-p-city .chosen-results li:last-child,
.mm-search-filter-p-place .chosen-results li:last-child,
.mm-search-filter-p-price-from .chosen-results li:last-child,
.mm-search-filter-p-price-to .chosen-results li:last-child,
.mm-search-filter-p-more .chosen-results li:last-child,
.mm-search-filter-pub-type .chosen-results li:last-child,
.mm-search-filter-pub-city .chosen-results li:last-child,
.mm-search-filter-pub-place .chosen-results li:last-child,
.mm-search-filter-pub-price-from .chosen-results li:last-child,
.mm-search-filter-pub-price-to .chosen-results li:last-child,
.mm-search-filter-pub-more .chosen-results li:last-child {
  border-bottom: 0;
  border-radius: 0 0 3px 3px;
}
.mm-search-filter-m-sex .chosen-results li.highlighted,
.mm-search-filter-m-place .chosen-results li.highlighted,
.mm-search-filter-m-city .chosen-results li.highlighted,
.mm-search-filter-m-date .chosen-results li.highlighted,
.mm-search-filter-m-more .chosen-results li.highlighted,
.mm-search-filter-pl-sex .chosen-results li.highlighted,
.mm-search-filter-pl-age .chosen-results li.highlighted,
.mm-search-filter-pl-city .chosen-results li.highlighted,
.mm-search-filter-pl-goal .chosen-results li.highlighted,
.mm-search-filter-pl-more .chosen-results li.highlighted,
.mm-search-filter-vc-sex .chosen-results li.highlighted,
.mm-search-filter-vc-age .chosen-results li.highlighted,
.mm-search-filter-vc-city .chosen-results li.highlighted,
.mm-search-filter-vc-goal .chosen-results li.highlighted,
.mm-search-filter-vc-more .chosen-results li.highlighted,
.mm-search-filter-p-type .chosen-results li.highlighted,
.mm-search-filter-p-city .chosen-results li.highlighted,
.mm-search-filter-p-place .chosen-results li.highlighted,
.mm-search-filter-p-price-from .chosen-results li.highlighted,
.mm-search-filter-p-price-to .chosen-results li.highlighted,
.mm-search-filter-p-more .chosen-results li.highlighted,
.mm-search-filter-pub-type .chosen-results li.highlighted,
.mm-search-filter-pub-city .chosen-results li.highlighted,
.mm-search-filter-pub-place .chosen-results li.highlighted,
.mm-search-filter-pub-price-from .chosen-results li.highlighted,
.mm-search-filter-pub-price-to .chosen-results li.highlighted,
.mm-search-filter-pub-more .chosen-results li.highlighted {
  background-color: #f6f6f6;
}
.mm-search-filter-m-sex .chosen-results .disabled-result,
.mm-search-filter-m-place .chosen-results .disabled-result,
.mm-search-filter-m-city .chosen-results .disabled-result,
.mm-search-filter-m-date .chosen-results .disabled-result,
.mm-search-filter-m-more .chosen-results .disabled-result,
.mm-search-filter-pl-sex .chosen-results .disabled-result,
.mm-search-filter-pl-age .chosen-results .disabled-result,
.mm-search-filter-pl-city .chosen-results .disabled-result,
.mm-search-filter-pl-goal .chosen-results .disabled-result,
.mm-search-filter-pl-more .chosen-results .disabled-result,
.mm-search-filter-vc-sex .chosen-results .disabled-result,
.mm-search-filter-vc-age .chosen-results .disabled-result,
.mm-search-filter-vc-city .chosen-results .disabled-result,
.mm-search-filter-vc-goal .chosen-results .disabled-result,
.mm-search-filter-vc-more .chosen-results .disabled-result,
.mm-search-filter-p-type .chosen-results .disabled-result,
.mm-search-filter-p-city .chosen-results .disabled-result,
.mm-search-filter-p-place .chosen-results .disabled-result,
.mm-search-filter-p-price-from .chosen-results .disabled-result,
.mm-search-filter-p-price-to .chosen-results .disabled-result,
.mm-search-filter-p-more .chosen-results .disabled-result,
.mm-search-filter-pub-type .chosen-results .disabled-result,
.mm-search-filter-pub-city .chosen-results .disabled-result,
.mm-search-filter-pub-place .chosen-results .disabled-result,
.mm-search-filter-pub-price-from .chosen-results .disabled-result,
.mm-search-filter-pub-price-to .chosen-results .disabled-result,
.mm-search-filter-pub-more .chosen-results .disabled-result {
  color: #999;
}
.mm-search-filter-m-sex .chosen-container,
.mm-search-filter-pl-sex .chosen-container,
.mm-search-filter-vc-sex .chosen-container,
.mm-search-filter-p-type .chosen-container,
.mm-search-filter-pub-type .chosen-container,
.mm-search-filter-m-sex .chosen-single,
.mm-search-filter-pl-sex .chosen-single,
.mm-search-filter-vc-sex .chosen-single,
.mm-search-filter-p-type .chosen-single,
.mm-search-filter-pub-type .chosen-single,
.mm-search-filter-m-sex__select,
.mm-search-filter-pl-sex__select,
.mm-search-filter-vc-sex__select,
.mm-search-filter-p-type__select,
.mm-search-filter-pub-type__select {
  border-radius: 3px 0 0 3px;
}
.mm-search-filter-p-place__input,
.mm-search-filter-pub-place__input {
  display: block;
  width: 100%;
  background: #fff;
  border: 1px solid #959595;
  border-right: none;
  height: 44px;
  line-height: 42px;
  font-size: 0.875rem;
  color: #666;
  padding: 0 20px;
}
.mm-search-filter-p-place__input:hover,
.mm-search-filter-pub-place__input:hover {
  background: #f6f6f6;
}
.mm-search-filter-p-place__input::placeholder,
.mm-search-filter-pub-place__input::placeholder {
  color: inherit;
}
.mm-search-filter-p-type {
  width: 135px;
}
.mm-search-filter-p-city {
  width: 210px;
}
.mm-search-filter-p-place {
  width: 255px;
}
.mm-search-filter-p-price-from {
  width: 125px;
}
.mm-search-filter-p-price-to {
  width: 130px;
}
.mm-search-filter-pub-type {
  width: 135px;
}
.mm-search-filter-pub-city {
  width: 210px;
}
.mm-search-filter-pub-place {
  width: 255px;
}
.mm-search-filter-pub-price-from {
  width: 125px;
}
.mm-search-filter-pub-price-to {
  width: 130px;
}
.mm-search-filter-pl-sex {
  width: 140px;
}
.mm-search-filter-pl-age {
  width: 212px;
}
.mm-search-filter-pl-city {
  width: 255px;
}
.mm-search-filter-pl-goal {
  width: 255px;
}
.mm-search-filter-vc-sex {
  width: 140px;
}
.mm-search-filter-vc-age {
  width: 212px;
}
.mm-search-filter-vc-city {
  width: 255px;
}
.mm-search-filter-vc-goal {
  width: 255px;
}
.mm-search-filter-m-sex {
  width: 135px;
}
.mm-search-filter-m-place {
  width: 210px;
}
.mm-search-filter-m-city {
  width: 255px;
}
.mm-search-filter-m-date {
  width: 255px;
}
.mm-search-filter-m-more,
.mm-search-filter-p-more,
.mm-search-filter-pub-more,
.mm-search-filter-pl-more,
.mm-search-filter-vc-more {
  border: 1px solid #959595;
  border-radius: 0 3px 3px 0;
  background-color: #fff;
}
.mm-search-filter-m-more:hover .mm-search-filter-m-more-label,
.mm-search-filter-p-more:hover .mm-search-filter-p-more-label,
.mm-search-filter-pub-more:hover .mm-search-filter-pub-more-label,
.mm-search-filter-pl-more:hover .mm-search-filter-pl-more-label,
.mm-search-filter-vc-more:hover .mm-search-filter-vc-more-label {
  background-color: #f6f6f6;
}
.mm-search-filter-m-more-label,
.mm-search-filter-p-more-label,
.mm-search-filter-pub-more-label,
.mm-search-filter-pl-more-label,
.mm-search-filter-vc-more-label {
  display: block;
  font-size: 0;
  border-radius: inherit;
  height: 42px;
  line-height: 42px;
  text-align: center;
  cursor: pointer;
}
.mm-search-filter-m-more-label__txt,
.mm-search-filter-p-more-label__txt,
.mm-search-filter-pub-more-label__txt,
.mm-search-filter-pl-more-label__txt,
.mm-search-filter-vc-more-label__txt {
  color: #666;
  font-size: 0.875rem;
  vertical-align: middle;
}
.mm-search-filter-m-more-label__icn,
.mm-search-filter-p-more-label__icn,
.mm-search-filter-pub-more-label__icn,
.mm-search-filter-pl-more-label__icn,
.mm-search-filter-vc-more-label__icn {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 40px;
  fill: #666;
}
.mm-search-filter-m-more-drop,
.mm-search-filter-p-more-drop,
.mm-search-filter-pub-more-drop,
.mm-search-filter-pl-more-drop,
.mm-search-filter-vc-more-drop {
  position: absolute;
  top: 100%;
  right: 0;
  width: 100%;
  background-color: #fff;
  border: 1px solid #959595;
  border-top: 0;
  padding: 10px 20px;
  z-index: 1;
  border-radius: 3px;
}
.mm-search-fields {
  font-size: 0;
}
.mm-search-query {
  display: inline-block;
  vertical-align: top;
  font-size: 1rem;
}
.mm-search-query {
  width: 100%;
  height: 45px;
  position: relative;
}
.mm-search-query__input {
  width: 100%;
  height: 100%;
  border-radius: 3px;
  background-color: #fff;
  border: 1px solid #959595;
  padding: 0 50px;
  line-height: 22px;
}
.mm-search-query__input::placeholder {
  font-style: italic;
  font-size: 0.75rem;
  color: #999;
}
.mm-search-query__submit {
  position: absolute;
  top: 0;
  right: 0;
  width: 24px;
  height: 24px;
  background: url("/search-glass.svg#base64") center no-repeat transparent;
  border: none;
  box-shadow: none;
  top: 50%;
  margin-top: -12px;
  right: 13px;
  opacity: 0.7;
}
.mm-search-query__submit:hover {
  opacity: 1;
}
.mm-search-query-mic {
  width: 17px;
  display: block;
  height: 29px;
  position: absolute;
  left: 13px;
  top: 50%;
  color: #b2b3b3;
  text-decoration: none;
  margin-top: -14.5px;
}
.mm-search-query-mic:hover {
  color: #808080;
}
.mm-search-query-mic__icn {
  fill: currentColor;
  display: block;
  width: 100%;
  height: 100%;
}
.mm-search-params {
  margin-top: 10px;
}
.mm-search-params:after {
  display: block;
  clear: both;
  content: '';
}
.mm-search-help {
  font-size: 0.75rem;
  float: left;
  padding-left: 20px;
  position: relative;
}
.mm-search-help__txt {
  color: #fff;
  text-shadow: 0px 1px 1px rgba(0,0,0,0.7);
  display: inline;
  vertical-align: middle;
}
.mm-search-help .icn-help-cont {
  top: 15px;
  left: 15px;
}
.mm-search-help-show {
  display: inline-block;
  vertical-align: middle;
  text-shadow: 0px 1px 1px rgba(0,0,0,0.7);
  color: #b1e47f;
  text-decoration: none;
  margin-left: 5px;
}
.mm-search-help-show__icn {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: 1px;
  width: 10px;
  height: 10px;
  fill: currentColor;
}
.mm-search-help-show__txt {
  display: inline;
  vertical-align: middle;
  text-decoration: underline;
}
.mm-search-help-show:hover .mm-search-help-show__txt {
  text-decoration: none;
}
.mm-search-help-tip {
  position: absolute;
  top: 0;
  left: 0;
  width: 15px;
  height: 15px;
  padding: 3px;
  border-radius: 50%;
}
.mm-search-attr {
  float: right;
}
.mm-search-attr-itm {
  text-align: right;
  display: inline-block;
  margin-left: 15px;
}
.mm-search-attr-itm__checkbox-1 {
  display: none;
}
.mm-search-attr-itm__label-1 {
  color: #fff;
  font-size: 0.75rem;
  padding-left: 25px;
  cursor: pointer;
  position: relative;
}
.mm-search-attr-itm__label-1:before {
  content: "";
  top: -3px;
  left: 0;
  position: absolute;
  background-image: url("/control-checkbox-off.svg#base64");
  width: 18px;
  height: 18px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.mm-search-attr-itm__checkbox-1:checked + .mm-search-attr-itm__label-1::before {
  background-image: url("/control-checkbox-on.svg#base64");
}
.mm-search-attr-itm {
  text-align: right;
  display: inline-block;
  margin-left: 15px;
}
.mm-search-attr-itm__checkbox-2 {
  display: none;
}
.mm-search-attr-itm__label-2 {
  color: #fff;
  font-size: 0.75rem;
  padding-left: 25px;
  cursor: pointer;
  position: relative;
}
.mm-search-attr-itm__label-2:before {
  content: "";
  top: -3px;
  left: 0;
  position: absolute;
  background-image: url("/control-checkbox-off.svg#base64");
  width: 18px;
  height: 18px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.mm-search-attr-itm__checkbox-2:checked + .mm-search-attr-itm__label-2::before {
  background-image: url("/control-checkbox-on.svg#base64");
}
.mm-search-attr-itm {
  text-align: right;
  display: inline-block;
  margin-left: 15px;
}
.mm-search-attr-itm__checkbox-3 {
  display: none;
}
.mm-search-attr-itm__label-3 {
  color: #fff;
  font-size: 0.75rem;
  padding-left: 25px;
  cursor: pointer;
  position: relative;
}
.mm-search-attr-itm__label-3:before {
  content: "";
  top: -3px;
  left: 0;
  position: absolute;
  background-image: url("/control-checkbox-off.svg#base64");
  width: 18px;
  height: 18px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.mm-search-attr-itm__checkbox-3:checked + .mm-search-attr-itm__label-3::before {
  background-image: url("/control-checkbox-on.svg#base64");
}
.mm-qnaire {
  display: block;
  color: inherit;
  text-decoration: none;
  border-radius: 3px;
  overflow: hidden;
  position: relative;
  margin-bottom: 12px;
}
.mm-qnaire_small {
  height: 180px;
}
.mm-qnaire_big {
  height: 372px;
}
.mm-qnaire__img {
  display: block;
  width: 100%;
  height: 100%;
}
.mm-qnaire_locked .mm-qnaire__img {
  filter: blur(10px);
}
.mm-qnaire-who {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 0 10px 10px;
  color: #fff;
  font-size: 0;
}
.mm-qnaire:hover .mm-qnaire-who {
  visibility: hidden;
}
.mm-qnaire_locked:hover .mm-qnaire-who {
  visibility: visible;
}
.mm-qnaire-who__name {
  font-size: 0.875rem;
  line-height: 1;
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.mm-qnaire_small .mm-qnaire-who__name {
  max-width: 100px;
}
.mm-qnaire_big .mm-qnaire-who__name {
  max-width: 210px;
}
.mm-qnaire-who__name::after {
  content: ",";
  margin-right: 5px;
}
.mm-qnaire-who__age {
  font-size: 0.875rem;
  line-height: 1;
  display: inline-block;
  vertical-align: middle;
}
.mm-qnaire-bday {
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: 34px;
  height: 34px;
}
.mm-qnaire-bday__icn {
  display: block;
  width: 100%;
  height: 100%;
}
.mm-qnaire:hover .mm-qnaire-bday {
  visibility: hidden;
}
.mm-qnaire_locked:hover .mm-qnaire-bday {
  visibility: visible;
}
.mm-qnaire-shadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.7);
  color: #fff;
  padding: 10px;
  visibility: hidden;
}
.mm-qnaire:hover .mm-qnaire-shadow {
  visibility: visible;
}
.mm-qnaire-author {
  font-size: 0;
  line-height: 1;
}
.mm-qnaire-author__name,
.mm-qnaire-author__age {
  display: inline-block;
  vertical-align: middle;
  font-size: 1.125rem;
}
.mm-qnaire-author__name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.mm-qnaire-author__name::after {
  content: ",";
  margin-right: 5px;
}
.mm-qnaire_small .mm-qnaire-author__name {
  max-width: 130px;
}
.mm-qnaire_big .mm-qnaire-author__name {
  max-width: 310px;
}
.mm-qnaire-loc {
  line-height: 1;
  margin: 5px 0 20px;
  font-size: 0.875rem;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.mm-qnaire-about {
  font-size: 0.75rem;
  line-height: 1.2;
}
.mm-qnaire-over {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("../img/bg/profile-bg-locked.png") repeat center transparent;
}
.mm-qnaire-lock {
  width: 45px;
  height: 65px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -22.5px;
  margin-top: -32.5px;
}
.mm-qnaire-lock__icn {
  display: block;
  width: 100%;
  height: 100%;
  fill: rgba(255,255,255,0.7);
  stroke: rgba(0,0,0,0.1);
  stroke-width: 3;
  filter: drop-shadow(0 1px 1px rgba(0,0,0,0.3));
}
.mm-qnaire-hint {
  position: absolute;
  bottom: -10px;
  right: -10px;
  width: 15px;
  height: 15px;
  padding: 3px;
  border-radius: 50%;
}
.mm-qnaire-hint .icn-help-cont {
  bottom: 13px;
  right: 13px;
}
.mm-qnaire-near {
  position: absolute;
  right: 10px;
  bottom: 10px;
  color: #fff;
}
.mm-qnaire-near__txt {
  margin: 0 auto;
  font-size: 0.6875rem;
  text-transform: uppercase;
  line-height: 1;
  border: 1px solid #717172;
  border-radius: 3px;
  background-color: rgba(0,0,0,0.5);
  padding-top: 7px;
  width: 105px;
  height: 36px;
  text-align: center;
}
.mm-qnaire-near__cnt {
  font-size: 0;
  font-weight: 300;
  line-height: 1;
  text-align: center;
}
.mm-qnaire-near__num {
  font-size: 5rem;
}
.mm-qnaire-near__per {
  font-size: 2.375rem;
}
.mm-qnaire:hover .mm-qnaire-near {
  visibility: hidden;
}
.mm-qnaire-match {
  position: absolute;
  right: 10px;
  top: 10px;
  color: #fff;
}
.mm-qnaire-match__txt {
  margin: 0 auto;
  font-size: 0.6875rem;
  text-transform: uppercase;
  line-height: 1;
  border: 1px solid #717172;
  border-radius: 3px;
  background-color: rgba(0,0,0,0.5);
  padding-top: 7px;
  width: 105px;
  height: 36px;
  text-align: center;
}
.mm-qnaire-match__txt span {
  font-size: 0.5625rem;
}
.mm-qnaire-match__cnt {
  font-size: 0;
  font-weight: 300;
  line-height: 1;
  text-align: center;
}
.mm-qnaire-match__num {
  font-size: 5rem;
}
.mm-qnaire-match__per {
  font-size: 2.375rem;
}
.mm-qnaire:hover .mm-qnaire-match {
  visibility: hidden;
}
.mm-qnaire-compare {
  position: absolute;
  left: 10px;
  top: 10px;
  color: #fff;
}
.mm-qnaire-compare__txt {
  font-size: 0.6875rem;
  text-transform: uppercase;
  line-height: 1;
  border: 1px solid #717172;
  border-radius: 3px;
  background-color: rgba(0,0,0,0.5);
  padding-top: 7px;
  width: 105px;
  height: 36px;
  text-align: center;
  margin: 0 auto;
}
.mm-qnaire-compare__cnt {
  font-size: 1.25rem;
  font-weight: bold;
  text-align: center;
  width: 60px;
  height: 60px;
  line-height: 60px;
  background: url("../img/main/matches-cloud.png") no-repeat center;
  background-size: contain;
  margin: 0 auto 5px;
}
.mm-qnaire:hover .mm-qnaire-compare {
  visibility: hidden;
}
.mm-user {
  border: 1px solid #b5b5b5;
  border-radius: 5px;
  background-color: #fff;
  padding: 10px;
}
.mm-user-type {
  color: #a4a4a4;
  text-transform: uppercase;
  text-align: center;
  font-size: 1.125rem;
  font-weight: bold;
  margin: 5px 0 10px;
}
.mm-user-view {
  position: relative;
}
.mm-user-photo {
  display: block;
  height: 320px;
  overflow: hidden;
}
.mm-user-photo__img {
  display: block;
  width: 100%;
  height: 100%;
}
.mm-user-status {
  position: absolute;
  left: 10px;
  bottom: 10px;
  height: 14px;
  padding: 0 10px;
  text-transform: lowercase;
  color: #fff;
  border-radius: 3px;
  font-size: 0.6875rem;
  font-weight: 300;
}
.mm-user-status_on {
  background-color: #95cd7d;
}
.mm-user-status_off {
  background-color: #cfcfcf;
}
.mm-user-bday {
  position: absolute;
  right: 54px;
  bottom: -18px;
  width: 40px;
  height: 40px;
}
.mm-user-info {
  padding: 15px 0 10px;
}
.mm-user-about {
  display: table;
  width: 100%;
}
.mm-user-who,
.mm-user-many {
  display: table-cell;
  vertical-align: middle;
}
.mm-user-who__name {
  display: block;
  text-align: left;
  line-height: 1;
  font-size: 1rem;
  color: #333;
  font-weight: bold;
  text-decoration: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.mm-user-who__name:hover {
  text-decoration: underline;
}
.mm-user-who__loc {
  color: #999;
  text-align: left;
  font-size: 0.75rem;
  line-height: 1;
  margin-top: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.mm-user-many {
  border-left: 1px solid #d3d3d3;
  text-align: center;
  width: 75px;
  color: #47aba3;
  font-weight: 300;
}
.mm-user-many__cnt {
  line-height: 1;
  font-size: 1.375rem;
}
.mm-user-many__txt {
  font-size: 0.625rem;
  line-height: 1;
}
.mm-born {
  background-color: #f7f7f7;
  border: 1px solid #d5d5d5;
  border-radius: 5px;
  position: relative;
  padding: 10px 20px;
}
.mm-born__title {
  text-transform: uppercase;
  color: #a2a2a2;
  line-height: 1;
  font-weight: bold;
  font-size: 1.125rem;
  margin-bottom: 15px;
}
.mm-born-hint {
  position: absolute;
  top: 3px;
  right: 3px;
  width: 15px;
  height: 15px;
  padding: 3px;
  border-radius: 50%;
}
.mm-born-hint .icn-help-cont {
  top: 15px;
  right: 15px;
}
.mm-born-type {
  width: 40px;
  height: 40px;
  position: absolute;
  top: -20px;
  right: 20px;
}
.mm-born-cnt {
  color: #57aebe;
  font-weight: 300;
  text-align: center;
  margin-bottom: 10px;
}
.mm-born-cnt__num {
  line-height: 1;
  font-size: 4.375rem;
}
.mm-born-cnt__txt {
  font-size: 1.125rem;
  line-height: 1;
  text-transform: uppercase;
}
.mm-born-people {
  display: block;
  list-style: none;
  font-size: 0;
  margin: 0 -3px;
}
.mm-born-people-cell {
  display: inline-block;
  vertical-align: top;
  padding: 0 3px;
  margin-bottom: 5px;
  width: 25%;
  font-size: 1rem;
}
.mm-born-people-itm {
  display: block;
  height: 75px;
  width: 100%;
}
.mm-born-people-itm__img {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 3px;
}
.mm-born-all {
  text-align: center;
  color: #999;
  padding: 10px 0;
}
.mm-born-all__lnk {
  font-size: 0.8125rem;
  color: inherit;
  line-height: 1;
  text-decoration: underline;
}
.mm-born-all__lnk:hover {
  text-decoration: none;
}
.mm-now-photos {
  display: block;
  list-style: none;
  font-size: 0;
}
.mm-now-photo {
  display: inline-block;
  vertical-align: top;
  margin-right: 5px;
}
.mm-now-photo__lnk {
  display: block;
  width: 75px;
  height: 50px;
}
.mm-now-photo__img {
  display: block;
  width: 100%;
  height: 100%;
}
.mm-now-photo:last-child {
  margin-right: 0;
}
.mm-now-video {
  font-size: 0;
}
.mm-now-video-poster,
.mm-now-video-info {
  display: inline-block;
  vertical-align: top;
  font-size: 1rem;
}
.mm-now-video-poster {
  width: 90px;
}
.mm-now-video-poster__lnk {
  display: block;
  height: 50px;
}
.mm-now-video-poster__img {
  display: block;
  width: 100%;
  height: 100%;
}
.mm-now-video-info {
  width: calc(100% - 90px);
  padding-left: 15px;
}
.mm-now-video-name {
  line-height: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.mm-now-video-name__lnk {
  font-size: 0.875rem;
  color: #2d2d2d;
  text-decoration: none;
  font-weight: bold;
}
.mm-now-video-name__lnk:hover {
  text-decoration: underline;
}
.mm-now-video-adds {
  font-size: 0;
  display: block;
  list-style: none;
  margin: 15px -10px 0;
}
.mm-now-video-adds-cell {
  border-right: 1px solid #4594a2;
  padding: 0 10px;
  display: inline-block;
  vertical-align: top;
}
.mm-now-video-adds-cell:last-child {
  border-right: none;
}
.mm-now-video-adds-itm {
  display: block;
  color: #4594a2;
  text-decoration: none;
}
.mm-now-video-adds-itm__icn,
.mm-now-video-adds-itm__txt {
  display: inline-block;
  vertical-align: middle;
}
.mm-now-video-adds-itm__icn {
  width: 19px;
  height: 17px;
  fill: currentColor;
  margin-right: 5px;
}
.mm-now-video-adds-itm__txt {
  font-size: 0.6875rem;
  line-height: 1;
}
.mm-now-video-adds-itm:hover .mm-now-video-adds-itm__txt {
  text-decoration: underline;
}
.mm-now-audio-song {
  margin-bottom: 5px;
  height: 25px;
}
.mm-now-audio-ctrls {
  font-size: 0;
  margin-bottom: 5px;
}
.mm-now-audio-ctrls:after {
  display: block;
  clear: both;
  content: '';
}
.mm-now-audio-play,
.mm-now-audio-name,
.mm-now-audio-time {
  display: inline-block;
  vertical-align: middle;
}
.mm-now-audio-play {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  box-shadow: none;
  border: none;
  position: relative;
  background-color: #4594a2;
}
.mm-now-audio-play::before {
  position: absolute;
  top: 3px;
  left: 5px;
  content: "";
  border: 6px solid transparent;
  border-top-width: 4px;
  border-bottom-width: 4px;
  border-left-color: #fff;
}
.mm-now-audio-song_playing .mm-now-audio-play::before,
.mm-now-audio-song_playing .mm-now-audio-play::after {
  position: absolute;
  top: 4px;
  width: 2px;
  height: 6px;
  content: "";
  background-color: #fff;
}
.mm-now-audio-song_playing .mm-now-audio-play::before {
  left: 4px;
  border: none;
}
.mm-now-audio-song_playing .mm-now-audio-play::after {
  right: 4px;
}
.mm-now-audio-name {
  width: calc(100% - 64px);
  font-size: 0.75rem;
  font-weight: bold;
  color: #343434;
  line-height: 1;
  padding: 0 5px 0 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.mm-now-audio-time {
  color: #399;
  font-size: 0.875rem;
  line-height: 1;
  width: 50px;
  float: right;
  text-align: right;
}
.mm-now-audio-bar {
  height: 2px;
  background-color: #c2c2c2;
  transition: height 0.1s 0s;
  cursor: pointer;
}
.mm-now-audio-song:hover .mm-now-audio-bar {
  height: 5px;
}
.mm-now-audio-bar__loaded {
  height: 100%;
  width: 0;
  background-color: #349a96;
}
.mm-now-audio-adds {
  font-size: 0;
  display: block;
  list-style: none;
  margin: 0 -10px;
}
.mm-now-audio-adds-cell {
  border-right: 1px solid #4594a2;
  padding: 0 10px;
  display: inline-block;
  vertical-align: top;
}
.mm-now-audio-adds-cell:last-child {
  border-right: none;
}
.mm-now-audio-adds-itm {
  display: block;
  color: #4594a2;
  text-decoration: none;
}
.mm-now-audio-adds-itm__icn,
.mm-now-audio-adds-itm__txt {
  display: inline-block;
  vertical-align: middle;
}
.mm-now-audio-adds-itm__icn {
  width: 19px;
  height: 17px;
  fill: currentColor;
  margin-right: 5px;
}
.mm-now-audio-adds-itm__txt {
  font-size: 0.6875rem;
  line-height: 1;
}
.mm-now-audio-adds-itm:hover .mm-now-audio-adds-itm__txt {
  text-decoration: underline;
}
.sw-sympathy {
  margin-bottom: 10px;
  position: relative;
  padding: 10px 20px;
  background-color: #f6f6f6;
  border: 1px solid #d5d5d5;
  border-radius: 5px;
}
.sw-sympathy__title {
  font-size: 1.125rem;
  color: #a2a2a2;
  margin-bottom: 15px;
  text-transform: uppercase;
}
.sw-sympathy-who {
  display: block;
  height: 260px;
}
.sw-sympathy-who__img {
  display: block;
  width: 100%;
  height: 100%;
}
.sw-sympathy-btns {
  font-size: 0;
  margin: 20px 0 10px;
}
.sw-sympathy-btns__express,
.sw-sympathy-btns__next {
  display: inline-block;
  vertical-align: middle;
  border-radius: 3px;
  font-size: 0.75rem;
}
.sw-sympathy-btns__express {
  width: 165px;
  height: 35px;
  margin-right: 5px;
  line-height: 33px;
}
.sw-sympathy-btns__next {
  height: 35px;
  background-color: #d5d5d5;
  line-height: 35px;
  color: #666;
  width: calc(100% - 170px);
  text-decoration: none;
  text-align: center;
  position: relative;
}
.sw-sympathy-btns__next::after {
  content: "";
  position: absolute;
  top: 50%;
  margin-top: -5px;
  right: -10px;
  border: 5px solid transparent;
  border-left-color: #d5d5d5;
}
.sw-sympathy-btns__next:hover {
  background-color: #bdb9b9;
}
.sw-sympathy-btns__next:hover::after {
  border-left-color: #bdb9b9;
}
.sw-chats {
  margin-bottom: 10px;
  position: relative;
  padding: 10px 20px;
  background-color: #f6f6f6;
  border: 1px solid #d5d5d5;
  border-radius: 5px;
}
.sw-chats__title {
  font-size: 1.125rem;
  color: #a2a2a2;
  margin-bottom: 15px;
  text-transform: uppercase;
}
.sw-chats-room {
  display: block;
  height: 260px;
}
.sw-chats-room-itm {
  display: table;
  width: 100%;
}
.sw-chats-room-ava,
.sw-chats-room-cont {
  display: table-cell;
  vertical-align: top;
}
.sw-chats-room-ava {
  width: 35px;
}
.sw-chats-room-cont {
  padding-left: 10px;
}
.sw-chats-who {
  display: block;
  height: 35px;
}
.sw-chats-who__img {
  width: 100%;
  height: 100%;
  border-radius: 3px;
}
.sw-chats-msg {
  padding: 10px;
  border-radius: 3px;
  border: 1px solid transparent;
  font-size: 0.6875rem;
  color: #666;
  line-height: 1.2;
  margin-bottom: 15px;
  position: relative;
}
.sw-chats-msg::before {
  content: "";
  border-top: inherit;
  border-left: inherit;
  position: absolute;
  width: 10px;
  height: 10px;
  top: 11px;
  left: -6px;
  transform: rotate(-45deg);
  background-color: inherit;
}
.sw-chats-msg_me {
  border-color: #d7d7d7;
  background-color: #fff;
}
.sw-chats-msg_you {
  background-color: #e1eded;
}
.sw-chats-btns {
  font-size: 0;
  margin: 20px 0 10px;
}
.sw-chats-btns__write,
.sw-chats-btns__new {
  display: inline-block;
  vertical-align: middle;
  border-radius: 3px;
  font-size: 0.75rem;
}
.sw-chats-btns__write {
  width: calc(100% - 90px);
  height: 35px;
  margin-right: 5px;
  line-height: 33px;
}
.sw-chats-btns__new {
  height: 35px;
  width: 85px;
  background-color: #d5d5d5;
  line-height: 35px;
  color: #666;
  text-decoration: none;
  text-align: center;
  position: relative;
}
.sw-chats-btns__new::after {
  content: "";
  position: absolute;
  top: 50%;
  margin-top: -5px;
  right: -10px;
  border: 5px solid transparent;
  border-left-color: #d5d5d5;
}
.sw-chats-btns__new:hover {
  background-color: #bdb9b9;
}
.sw-chats-btns__new:hover::after {
  border-left-color: #bdb9b9;
}
.sw-pin2pin {
  margin-bottom: 10px;
  position: relative;
  padding: 10px 20px;
  background-color: #f6f6f6;
  border: 1px solid #d5d5d5;
  border-radius: 5px;
}
.sw-pin2pin__title {
  font-size: 1.125rem;
  color: #a2a2a2;
  margin-bottom: 15px;
  text-transform: uppercase;
}
.sw-pin2pin-stream {
  width: 235px;
  height: 180px;
}
.sw-pin2pin-act {
  color: #343434;
  font-size: 0.875rem;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  margin: 19px 0;
}
.sw-pin2pin-btns {
  font-size: 0;
  text-align: center;
}
.sw-pin2pin-btns__sep {
  display: inline-block;
  vertical-align: top;
  margin: 0 20px;
  height: 63px;
  width: 1px;
  background-color: #b4b4b4;
}
.sw-pin2pin-answer,
.sw-pin2pin-decline {
  display: inline-block;
  vertical-align: top;
  color: inherit;
  text-decoration: none;
}
.sw-pin2pin-answer__btn,
.sw-pin2pin-decline__btn {
  width: 70px;
  height: 70px;
  padding: 15px;
  display: block;
  border-radius: 50%;
}
.sw-pin2pin-answer__btn .icon,
.sw-pin2pin-decline__btn .icon {
  background-image: url("../img/icons/videochat.png");
  background-position: 0px 0px;
  width: 40px;
  height: 40px;
}
.sw-pin2pin-answer__icn,
.sw-pin2pin-decline__icn {
  width: 100%;
  height: 100%;
  display: block;
}
.sw-pin2pin-answer__txt,
.sw-pin2pin-decline__txt {
  font-size: 0.8125rem;
  color: #999;
  text-align: center;
  margin-top: 5px;
  display: block;
}
.sw-wantgo {
  margin-bottom: 10px;
  position: relative;
  padding: 10px 20px;
  background-color: #f6f6f6;
  border: 1px solid #d5d5d5;
  border-radius: 5px;
}
.sw-wantgo__title {
  font-size: 1.125rem;
  color: #a2a2a2;
  margin-bottom: 15px;
  text-transform: uppercase;
}
.sw-wantgo-who {
  font-size: 0;
}
.sw-wantgo-thumb,
.sw-wantgo-info {
  display: inline-block;
  vertical-align: top;
  font-size: 1rem;
}
.sw-wantgo-thumb {
  width: calc(100% - 130px);
}
.sw-wantgo-info {
  padding-left: 15px;
  width: 130px;
}
.sw-wantgo-ava {
  height: 135px;
  border: 1px solid #b3b3b3;
  padding: 5px;
}
.sw-wantgo-ava__img {
  display: block;
  width: 100%;
  height: 100%;
}
.sw-wantgo-name {
  line-height: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.sw-wantgo-name__lnk {
  font-size: 0.875rem;
  color: #343434;
  text-decoration: none;
  font-weight: bold;
}
.sw-wantgo-name__lnk:hover {
  text-decoration: underline;
}
.sw-wantgo-status {
  width: 55px;
  height: 15px;
  line-height: 15px;
  border-radius: 3px;
  margin: 15px 0;
  font-size: 0.6875rem;
  text-align: center;
  font-weight: 300;
  text-transform: lowercase;
  color: #fff;
}
.sw-wantgo-status_on {
  background-color: #95cd7d;
}
.sw-wantgo-status_off {
  background-color: #bababa;
}
.sw-wantgo-about {
  color: #999;
  font-size: 0.75rem;
  line-height: 1.2;
  height: 70px;
  overflow: hidden;
}
.sw-wantgo-about a {
  color: inherit;
  text-decoration: underline;
}
.sw-wantgo-about a:hover {
  text-decoration: none;
}
.sw-wantgo-tip {
  background-color: #fdfdee;
  padding: 10px 15px;
  font-style: italic;
  font-size: 0.75rem;
  color: #787878;
  position: relative;
  border: 1px solid #b4b4b4;
  border-radius: 3px;
  margin: 15px 0;
}
.sw-wantgo-tip::after {
  content: "";
  background-color: inherit;
  transform: rotate(45deg);
  width: 30px;
  height: 30px;
  border-top: inherit;
  border-left: inherit;
  position: absolute;
  top: -15px;
  left: 35px;
}
.sw-wantgo-tip__cont {
  height: 55px;
  background-color: inherit;
  line-height: 1.2;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.sw-wantgo-tip__cont a {
  color: #47aca5;
  text-decoration: underline;
}
.sw-wantgo-tip__cont a:hover {
  text-decoration: none;
}
.sw-wantgo-btn {
  margin: 25px 0 15px;
}
.sw-wantgo-another {
  color: #999;
  font-size: 0.8125rem;
  text-align: center;
}
.sw-wantgo-another__lnk {
  color: inherit;
  text-decoration: underline;
}
.sw-wantgo-another__lnk:hover {
  text-decoration: none;
}
.sw-poll-before {
  margin-bottom: 10px;
  position: relative;
  padding: 10px 20px;
  background-color: #f6f6f6;
  border: 1px solid #d5d5d5;
  border-radius: 5px;
}
.sw-poll-before__help {
  width: 15px;
  height: 15px;
  padding: 3px;
  position: absolute;
  top: 3px;
  right: 3px;
}
.sw-poll-before__help .icn-help-cont {
  top: 15px;
  right: 15px;
}
.sw-poll-before__title {
  font-size: 1.125rem;
  color: #a2a2a2;
  margin-bottom: 15px;
  text-transform: uppercase;
}
.sw-poll-before-btn {
  height: 35px;
  font-size: 0.9375rem;
  width: 100%;
  border-radius: 5px;
  margin: 20px 0 5px;
}
.sw-poll-before__quest {
  color: #47aca5;
  font-size: 1.5rem;
  font-style: italic;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 20px;
}
.sw-poll-before__choice {
  background-color: #fff;
  border: 1px solid #c2c2c2;
  border-radius: 3px;
  padding: 5px 10px;
}
.sw-poll-before__choice-head {
  font-size: 0.6875rem;
  font-weight: bold;
  color: #666;
}
.sw-poll-before__list {
  list-style: none;
  padding: 5px;
}
.sw-poll-before__item {
  border-bottom: 1px dotted #c1c1c1;
}
.sw-poll-before__item:last-child {
  border-bottom: 0;
}
.sw-poll-before__radio {
  display: none;
}
.sw-poll-before__label {
  line-height: 1;
  display: inline-block;
  font-weight: 300;
  color: #333;
  font-size: 1.125rem;
  cursor: pointer;
  padding: 10px 0 10px 25px;
  position: relative;
}
.sw-poll-before__label::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  background-image: url("/control-radio-off.svg#base64");
  width: 18px;
  height: 18px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.sw-poll-before__radio:checked + .sw-poll-before__label::before {
  background-image: url("/control-radio-on.svg#base64");
}
.sw-poll-before__next {
  text-align: center;
  color: #999;
  font-size: 0.875rem;
  cursor: pointer;
}
.sw-poll-before__next a {
  text-decoration: none;
  border-bottom: 1px dashed currentColor;
  color: inherit;
}
.sw-poll-before__next a:hover {
  border-bottom: none;
}
.sw-newsletter {
  margin-bottom: 10px;
  position: relative;
  padding: 10px 20px;
  background-color: #f6f6f6;
  border: 1px solid #d5d5d5;
  border-radius: 5px;
}
.sw-newsletter__help {
  width: 15px;
  height: 15px;
  padding: 3px;
  position: absolute;
  top: 3px;
  right: 3px;
}
.sw-newsletter__help .icn-help-cont {
  top: 15px;
  right: 15px;
}
.sw-newsletter__title {
  font-size: 1.125rem;
  color: #a2a2a2;
  margin-bottom: 15px;
  text-transform: uppercase;
}
.sw-newsletter__submit {
  height: 35px;
  font-size: 0.9375rem;
  width: 100%;
  border-radius: 5px;
  margin: 15px 0 5px;
}
.sw-newsletter__quest {
  color: #47aca5;
  font-size: 1.5rem;
  font-style: italic;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 15px;
}
.sw-newsletter__desc {
  color: #2d2d2d;
  font-size: 0.75rem;
  line-height: 1.2;
  margin-bottom: 15px;
}
.sw-newsletter__email {
  border: 1px solid #c2c2c2;
  border-radius: 3px;
  height: 35px;
  background: #fff;
  width: 100%;
  box-shadow: none;
  padding: 0 15px;
  font-size: 0.875rem;
}
.sw-newsletter__email::placeholder {
  color: #666;
  font-style: italic;
}
.sw-contests {
  margin-bottom: 10px;
  position: relative;
  padding: 10px 20px;
  background-color: #f6f6f6;
  border: 1px solid #d5d5d5;
  border-radius: 5px;
}
.sw-contests__help {
  width: 15px;
  height: 15px;
  padding: 3px;
  position: absolute;
  top: 3px;
  right: 3px;
}
.sw-contests__help .icn-help-cont {
  top: 15px;
  right: 15px;
}
.sw-contests__title {
  font-size: 1.125rem;
  color: #a2a2a2;
  margin-bottom: 15px;
  text-transform: uppercase;
}
.sw-contests__quest {
  color: #47aca5;
  font-size: 1.5rem;
  font-style: italic;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 15px;
}
.sw-contests__desc {
  color: #2d2d2d;
  font-size: 0.75rem;
  line-height: 1.2;
  margin-bottom: 15px;
}
.sw-contests-itm {
  display: block;
  overflow: hidden;
  height: 140px;
}
.sw-contests-itm__img {
  display: block;
  width: 100%;
  height: 100%;
}
.sw-contests-goto {
  text-align: center;
  color: #989898;
  margin: 5px 0 3px;
}
.sw-contests-goto__lnk {
  color: inherit;
  font-size: 0.875rem;
  text-decoration: none;
  border-bottom: 1px dashed currentColor;
}
.sw-contests-goto__lnk:hover {
  border-bottom: none;
}
.nav-side {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  background-color: #f4f4f4;
}
.nav-side-top-my-info {
  color: #fff;
  background-color: #9ccd6a;
  font-size: 0.875rem;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  border: 1px solid #3b871b;
  border-radius: 5px;
  width: 90%;
  margin: 15px auto 20px auto;
  padding: 10px 20px;
  position: relative;
}
.nav-side-top-my-info::after {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  bottom: -8px;
  left: 50%;
  transform: rotate(45deg);
  border: inherit;
  border-top-color: transparent;
  border-left-color: transparent;
  margin-left: -7.5px;
  background-color: inherit;
}
.nav-side-top-my-info__help {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  padding: 3px;
}
.nav-side-top-my-info__help .icn-help-cont {
  right: 15px;
  top: 15px;
}
.nav-side-top-tape {
  margin: 15px auto 0;
  width: 90%;
}
.nav-side-top-tape-btn {
  display: block;
  position: relative;
  border-radius: 5px;
  background-color: #cfcfcf;
  border: 1px solid #a9a9a9;
  text-decoration: none;
  color: #fff;
  padding: 6px 0;
  font-size: 1.125rem;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
}
.nav-side-top-my-info__txt,
.nav-side-top-tape-btn__txt {
  text-shadow: 1px 1px 1px rgba(50,50,50,0.5);
}
.nav-side-top-tape-btn__help {
  position: absolute;
  top: -10px;
  right: -10px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  padding: 3px;
}
.nav-side-top-tape-btn__help .icn-help-cont {
  right: 15px;
  top: 15px;
}
.nav-side-top-tape-desc {
  color: #666;
  font-size: 0.75rem;
  padding: 10px 20px 0;
}
.my-side-menu {
  list-style: none;
  font-size: 0.8125rem;
}
.my-side-menu__item {
  border-bottom: 1px solid #cfcfcf;
  height: 30px;
  position: relative;
  cursor: pointer;
  color: #666;
}
.my-side-menu__item:hover,
.my-side-menu__item_active {
  background-color: #69bdb7;
  background: linear-gradient(to bottom, #69bdb7 0%, #65b5ad 50%, #5eaba4 100%);
  color: #fff;
}
.my-side-menu__edit {
  fill: #447b76;
  position: absolute;
  top: 7px;
  right: 25px;
  cursor: pointer;
  height: 17px;
  width: 17px;
}
.my-side-menu__link {
  display: block;
  color: inherit;
  height: 100%;
  text-decoration: none;
}
.my-side-menu__label {
  padding: 0 40px 0 30px;
  line-height: 29px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.my-side-menu__icon {
  height: 19px;
  width: 19px;
  position: absolute;
  top: 50%;
  margin-top: -9.5px;
  fill: currentColor;
  left: 25px;
}
.my-side-menu__count {
  background-color: #4594a2;
  color: #fff;
  min-width: 20px;
  height: 20px;
  line-height: 20px;
  padding: 0 3px;
  border-radius: 3px;
  text-align: center;
  font-size: 0.5625rem;
  position: absolute;
  top: 50%;
  margin-top: -10px;
  right: 20px;
}
.setting-menu {
  color: #666;
  font-size: 0.8125rem;
  list-style: none;
  margin-top: 10px;
}
.setting-menu__item {
  border-top: 1px solid #cfcfcf;
  height: 40px;
  position: relative;
}
.setting-menu__item:hover,
.setting-menu__item_active {
  color: #69bdb7;
}
.setting-menu__link {
  text-decoration: none;
  display: block;
  color: inherit;
  height: 100%;
}
.setting-menu__label {
  padding: 0 5px 0 50px;
  line-height: 39px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.setting-menu__icon {
  height: 19px;
  width: 19px;
  position: absolute;
  top: 50%;
  margin-top: -9.5px;
  fill: #a7a2a2;
  left: 20px;
}
.footer {
  height: 40px;
  background-color: #089090;
}
.footer-content {
  height: 100%;
  color: #fff;
  position: relative;
}
.footer_is-dialog {
  display: none;
}
.footer-copy-wrap {
  height: 100%;
  display: table;
  position: absolute;
  left: 0;
  top: 0;
}
.footer-copy {
  font-size: 0;
  display: table-cell;
  vertical-align: middle;
  list-style: none;
}
.footer-copy__year,
.footer-copy__title,
.footer-copy__right {
  display: inline-block;
  font-size: 0.75rem;
  border-right: 1px solid currentColor;
  padding: 0 6px;
}
.footer-copy__year {
  padding-left: 11px;
}
.footer-copy__right {
  border-right: none;
}
.footer-menu-wrap {
  display: table;
  width: 100%;
  height: 100%;
}
.footer-menu {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  margin: 0 auto;
  list-style: none;
  font-size: 0;
}
.footer-menu__item {
  display: inline-block;
  font-size: 0.75rem;
  border-right: 1px solid currentColor;
  padding: 0 6px;
}
.footer-menu__item:last-child {
  border-right: none;
}
.footer-menu__link {
  color: inherit;
  text-decoration: none;
  display: block;
}
.footer-menu__link:hover {
  text-decoration: underline;
}
.footer-social-wrap {
  display: table;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}
.footer-social {
  display: table-cell;
  vertical-align: middle;
}
.footer-social-txt {
  display: inline-block;
  font-size: 0.75rem;
  margin-right: 15px;
}
.footer-social-list {
  display: inline-block;
  vertical-align: middle;
  font-size: 0;
  list-style: none;
}
.footer-social-list__item {
  font-size: 0.75rem;
  display: inline-block;
  margin: 0 4px;
  opacity: 0.7;
}
.footer-social-list__item:hover {
  opacity: 1;
}
.footer-social-list__item:last-child {
  margin-right: 11px;
}
.footer-social-list__link {
  display: block;
  width: 30px;
  height: 30px;
}
.footer-social-list__icn {
  width: 100%;
  height: 100%;
  fill: #fff;
  display: block;
  padding: 2px;
}
.footer-social-list__item_fb .footer-social-list__icn {
  padding: 0;
}
.custom-scroll_container {
  overflow: hidden !important;
}
.custom-scroll_inner {
  overflow: scroll;
  height: 100%;
}
.custom-scroll_inner::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.custom-scroll_inner {
  -ms-overflow-style: none;
}
.custom-scroll_bar-y,
.custom-scroll_bar-x {
  cursor: default;
  position: absolute;
  border-radius: 3px;
  z-index: 12;
  background: #000;
  opacity: 0.4;
  user-select: none;
  transition: opacity 0.2s;
}
.custom-scroll_bar-y {
  top: 0;
  right: 1px;
  width: 8px;
}
.custom-scroll_bar-x {
  left: 0;
  bottom: 1px;
  height: 8px;
}
.custom-scroll_bar-y:hover,
.custom-scroll_bar-x:hover {
  opacity: 0.7;
}
.custom-scroll_bar-y:active,
.custom-scroll_bar-x:active {
  opacity: 1;
}
.custom-scroll_hidden-y > .custom-scroll_bar-y,
.custom-scroll_hidden-x > .custom-scroll_bar-x {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s, visibility step-end 0.3s;
}
