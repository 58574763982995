.people-words-wrap
  background: url("../img/bg/profile-bg-locked.png") repeat center #3b8e86
  padding-bottom 25px

.people-words
  text-align center

  &__title
    padding: 17px 0 25px
    color #fff
    font-size (24/16)rem
    font-weight bold
    text-transform uppercase

.people-words-lst
  margin: 0 -5px 15px
  height: 80px
  overflow: hidden

  .people-words_opened &
    height: auto

.people-words-cell
  width: 180px
  padding: 0 5px
  margin-bottom: 10px
  display: inline-block
  vertical-align: top

.people-words-itm
  background-color rgba(#000, .05)
  border-radius 3px
  color #fff
  font-size: (15/16)rem
  height 30px
  line-height 30px
  box-shadow inset 0px 1px 1px -1px rgba(0,0,0,0.27),
             0px 1px 1px -1px rgba(255,255,255,.51),
             inset 0px 0px 5px 0px rgba(0,0,0,0.1)

.people-words-all
  color: #fff
  display: inline-block
  cursor: pointer
  text-decoration: none

  &__txt
    vertical-align: middle
    text-decoration: underline

    .people-words-all:hover &
      text-decoration: none

  &__icn
    width: 10px
    height: 10px
    display: inline-block
    vertical-align: middle
    fill: currentColor
    position: relative
    top: 1px
    left: 2px

    .people-words_opened &
      transform: rotate(180deg)
