clearfix()
  &::after
    content: ""
    display: table
    clear: both

nowrap()
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap

grid()
  .grid-row
    margin: 0 -6px
    clearfix()

  for i in 1..12
    .grid-col-{i}
      width (i*100/12)%
      float left
      padding 0 6px
      min-height 1px

  for i in 1..8
    .grid8-col-{i}
      width (i*100/8)%
      float left
      padding 0 6px
      min-height 1px

clearfix()
  &:after
    display block
    clear both
    content ''
    
sprite($name, $sprites)
  for $icn in $sprites
    .{$name}_{$icn}
      width: $sprites[$icn][4]
      height: $sprites[$icn][5]
      background-position: $sprites[$icn][2] $sprites[$icn][3]
      background-image: url("../img/" + $sprites[$icn][8])

ctrl-radio()
  width: 18px
  height: 18px
  background-position: center
  background-repeat: no-repeat
  background-size: contain

ctrl-checkbox()
  width: 18px
  height: 18px
  background-position: center
  background-repeat: no-repeat
  background-size: contain

ctrl-radio-on()
  background-image: url("/control-radio-on.svg#base64")

ctrl-radio-off()
  background-image: url("/control-radio-off.svg#base64")
  ctrl-radio()

ctrl-checkbox-on()
  background-image: url("/control-checkbox-on.svg#base64")

ctrl-checkbox-off()
  background-image: url("/control-checkbox-off.svg#base64")
  ctrl-checkbox()

.icon
  display inline-block
  vertical-align top
  



spritesmithWidth($sprite) {
  width: $sprite[4];
}

spritesmithHeight($sprite) {
  height: $sprite[5];
}

spritesmithPosition($sprite) {
  background-position: $sprite[2] $sprite[3];
}

spritesmithImage($sprite) {
  background-image: url($sprite[8]);
}

spritesmith($sprite) {
  spritesmithImage($sprite)
  spritesmithPosition($sprite)
  spritesmithWidth($sprite)
  spritesmithHeight($sprite)
}

sprites($sprites) {
  for $sprite in $sprites {
    $sprite_name = $sprite[9];
    .{$sprite_name} {
      spritesmith($sprite);
    }
  }
}