.people-services-wrap
  box-shadow inset 0px 15px 25px -15px rgba(#000, .5)

.people-services
  padding: 45px 0 55px

  &__title
    color: #666
    text-align: center
    font-size: (33/16)rem
    line-height: 1
    font-weight: 300
    margin-bottom: 30px

    span
      text-transform: uppercase
