.people-both-wrap
  padding-bottom: 65px

.people-both
  padding: 45px 80px
  position: relative
  background-color: #fff

  &__title
   text-align: center
   color: #666
   font-size: (33/16)rem
   line-height: 1
   font-weight: 300
   text-transform: uppercase

  &__desc
    font-size: (14/16)rem
    text-align: center
    line-height: 1.2
    color: #666
    margin: 25px 0

  &::after
  &::before
    position: absolute
    content: ""
    width: 50%
    bottom: 20px
    box-shadow: 0 0 60px 10px rgba(#000, .5)
    z-index: $zi-people-both

  &::before
    left: 10px
    transform: rotate(-5deg)

  &::after
    right: 10px
    transform: rotate(5deg)


  &-slider
    .slick-dots
      position: absolute
      bottom: -50px
      left: 50%
      margin-left: -30px

      li
        display: inline-block
        vertical-align: middle
        margin: 0 3px
        width: 15px
        height: @width
        border-radius: 50%
        background-color: #cbcbcb
        padding: 2px
        color: transparent

      .slick-active button
        background: linear-gradient(to bottom, #96cbc8 0%, #28706d 100%)

      button
        background-color: transparent
        display: block
        width: 100%
        height: 100%
        border-radius: 50%

    .slick-arrow
      position: absolute
      top: 50%
      z-index: 1
      background-size: cover
      width: 25px
      height: 35px
      background: url("/arrow-left-green.svg#base64") center no-repeat transparent
      opacity: .6
      color: transparent

      &:hover
        opacity: 1

    .slick-prev
      left: 25px

    .slick-next
      right: 25px
      transform: rotate(180deg)


  &-stats
    position: absolute
    top: 200px
    left: 50%
    transform: translateX(-50%)

    &-hearts
      margin: 0 auto 60px

      &__img
        max-width: 200px
        margin: 0 auto
        display: block

    &-count
      color: #a7cdca
      font-size: (30/16)rem
      line-height: 1
      text-align: center
      text-transform: uppercase
      font-weight: bold




  &-luser
    font-size: 0

    &-ava
      display: inline-block
      vertical-align: middle
      width: 180px
      height: 180px

      &__img
        width: 100%
        height: 100%
        border: 3px solid #c2ecea
        border-radius: 3px

    &-query
      width: calc(100% - 180px)
      display: inline-block
      vertical-align: middle
      font-size: (14/16)rem
      line-height: 1.2
      padding-left: 15px

      &__title
        font-weight: bold
        color: #666

      &__text
        color: #999

  &-ruser
    font-size: 0

    &-ava
      display: inline-block
      vertical-align: middle
      width: 180px
      height: 180px

      &__img
        display: block
        width: 100%
        height: 100%
        border: 3px solid #c2ecea
        border-radius: 3px

    &-query
      display: inline-block
      vertical-align: middle
      font-size: (14/16)rem
      line-height: 1.2
      width: calc(100% - 180px)
      padding-right: 15px

      &__title
        font-weight: bold
        color: #666

      &__text
        color: #999

  &-linfo
  &-rinfo
    width: 180px

  &-rinfo
    float: right

  &-story
    &-top
      display: table
      width: 100%
      margin-bottom: 10px

    &-lft
    &-rgt
    &-mdl
      display: table-cell
      vertical-align: middle
      width: 33%

    &-rgt
      text-align: right

    &-info
      display: table
      width: 100%

      &-name
        color: #343434
        font-size: (18/16)rem
        font-weight: 300
        width: 180px

        &__lnk
          text-decoration: none
          color: inherit

          &:hover
            text-decoration: underline

      &__about
        font-size: (14/16)rem
        font-weight: bold
        width: 180px

      &-lft
        display: table-cell
        width: 50%
        vertical-align: bottom

      &-rgt
        display: table-cell
        width: 50%
        vertical-align: bottom
        text-align: right
