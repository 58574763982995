.mm-now-audio-song
  margin-bottom: 5px
  height: 25px

.mm-now-audio-ctrls
  font-size: 0
  margin-bottom: 5px

  clearfix()

.mm-now-audio-play
.mm-now-audio-name
.mm-now-audio-time
  display: inline-block
  vertical-align: middle

.mm-now-audio-play
  width: 14px
  height: 14px
  border-radius: 50%
  box-shadow: none
  border: none
  position: relative
  background-color: #4594a2

  &::before
    position: absolute
    top: 3px
    left: 5px
    content: ""
    border: 6px solid transparent
    border-top-width: 4px
    border-bottom-width: 4px
    border-left-color: #fff

  .mm-now-audio-song_playing &::before
  .mm-now-audio-song_playing &::after
    position: absolute
    top: 4px
    width: 2px
    height: 6px
    content: ""
    background-color: #fff

  .mm-now-audio-song_playing &::before
    left: 4px
    border: none

  .mm-now-audio-song_playing &::after
    right: 4px

.mm-now-audio-name
  width: calc(100% - 64px) // 14+50
  font-size: (12/16)rem
  font-weight: bold
  color: #343434
  line-height: 1
  padding: 0 5px 0 10px

  nowrap()

.mm-now-audio-time
  color: #339999
  font-size: (14/16)rem
  line-height: 1
  width: 50px
  float: right
  text-align: right

.mm-now-audio-bar
  height: 2px
  background-color: #c2c2c2
  transition: height .1s 0s
  cursor: pointer

  .mm-now-audio-song:hover &
    height: 5px

  &__loaded
    height: 100%
    width: 0
    background-color: #349a96

.mm-now-audio-adds
  font-size: 0
  display: block
  list-style: none
  margin: 0 -10px

.mm-now-audio-adds-cell
  border-right: 1px solid #4594a2
  padding: 0 10px
  display: inline-block
  vertical-align: top

  &:last-child
    border-right: none

.mm-now-audio-adds-itm
  display: block
  color: #4594a2
  text-decoration: none

  &__icn
  &__txt
    display: inline-block
    vertical-align: middle

  &__icn
    width: 19px
    height: 17px
    fill: currentColor
    margin-right: 5px

  &__txt
    font-size: (11/16)rem
    line-height: 1

    .mm-now-audio-adds-itm:hover &
      text-decoration: underline
